(function () {
  const sampleSelector = `.sample-forbidden`;
  const modalSelector = '.modal';
  const loaderSelector = '.loader';

  const leagueSamplesApp = function () {
    const showAlert = () => {
      $(loaderSelector).addClass('d-none');
      window.swalWithBootstrapButtons.fire({
        title: 'Time for a mulligan!',
        html: Mustache.render($('#mustache_samples_alert_text').html()),
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
      });
    };

    const handleWithForbidden = (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      showAlert();
    };

    const findForbiddenButtons = (elements = $(sampleSelector)) => {
      elements.each(function(_, item){
        if (item.outerHTML.includes('<a ')) return;

        $(item.form).off()
                    .on('submit', function(e) {
                      e.preventDefault();
                      e.stopPropagation();
                    });
        $(item).on('click', handleWithForbidden);
      });
    }

    const bind = () => {
      $(modalSelector).on('show.bs.modal', findForbiddenTargets)

      $(sampleSelector).off()
      $(document).on('click', sampleSelector, handleWithForbidden);

      findForbiddenButtons();
    };

    const findForbiddenTargets = (e) => {
      $(e.target).find(sampleSelector)
                 .off()
                 .on('click', handleWithForbidden);
      findForbiddenButtons($(e.target).find(sampleSelector));
    };

    $(document).on('turbolinks:load', bind);

    return {
      showAlert
    }
  };

  if (typeof window !== "undefined") {
    window.leagueSamplesApp = leagueSamplesApp();
  }

  return leagueSamplesApp;
})();
