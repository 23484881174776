(function () {
  const bindCountry = () => {
    $("#course_country_id").on("change", function () {
      const country_id = $(this).val();
      const newStates = gon.states.filter((state) => state.country_id === country_id);
      const stateOptions = newStates
        .map((state) => $("<option>").attr("value", state.id).text(state.name)[0].outerHTML)
        .join("");
      $("#course_state_id").html(stateOptions);
    });
  };

  const bindBusinessCourseOptions = () => {
    $("#course_business_course")
      .on("change", function () {
        if ($(this).prop("checked")) {
          $("#course_hole_numbers_9").closest(".check-box").hide();
          // $('#course_simulator_software').parent().show();
        } else {
          $("#course_hole_numbers_9").closest(".check-box").show();
          // $('#course_simulator_software').val('').parent().hide();
        }
      })
      .trigger("change");
  };

  const courseFormApp = function () {
    $(document).on("turbolinks:load", () => {
      if (!$("body").hasClass("controller-courses-courses")) return;

      window.select2Ajax.bindselect2Ajax(".select2-ajax");

      bindCountry();
      bindBusinessCourseOptions();
    });
  };

  if (typeof window !== "undefined") {
    window.courseFormApp = courseFormApp;
  }
})();
