(function () {
  const modalMenuSelector = "#mobile-reports-menu";
  const reportButtonSelector = "#change-report-button";

  const mobileReportsApp = function () {
    const openMenu = (e) => {
      e?.preventDefault();
      $(modalMenuSelector).modal("show");
    };

    const removeDesktopItems = function () {
      $("aside").remove();
      $(".breadcrumb-item:not(.active)").remove();
    };

    const showMobileItems = function () {
      $(reportButtonSelector).removeClass("d-none");
    };

    const openMenuIfNecessary = function () {
      if (!window.location.href.includes("openMobileReportsMenu")) return;

      openMenu();
    };

    const dispatchOpenReportEvent = () => {
      if (!window.ReactNativeWebView) return;

      if (window.location.href.includes("openMobileReportsMenu")) return;

      window.ReactNativeWebView.postMessage(window.controllerName);
    };

    const bindModalEvents = () => {
      if (!window.ReactNativeWebView) return;

      $(modalMenuSelector).on("show.bs.modal", () => {
        window.ReactNativeWebView.postMessage("menu");
      });
      $(modalMenuSelector).on("hide.bs.modal", () => {
        window.ReactNativeWebView.postMessage(window.controllerName);
      });
    };

    const removeFullSidebarProperty = () => {
      if (!window.ReactNativeWebView && !window.requestFromWebView) return;

      setTimeout(() => {
        $("[data-sidebartype]").removeAttr("data-sidebartype");
        $("[data-sidebar-position]").attr("data-sidebar-position", "none");
        $("[data-header-position]").attr("data-header-position", "relative");
        $("header").remove();
      }, 300);
    };

    $(modalMenuSelector).on("hidden.bs.modal");

    $(document).on("click", "header", openMenu);
    $(document).on("click", reportButtonSelector, openMenu);

    $(document).on("turbolinks:load", removeDesktopItems);
    $(document).on("turbolinks:load", showMobileItems);
    $(document).on("turbolinks:load", openMenuIfNecessary);
    $(document).on("turbolinks:load", dispatchOpenReportEvent);
    $(document).on("turbolinks:load", bindModalEvents);
    $(document).on("turbolinks:load", removeFullSidebarProperty);
  };

  if (typeof window !== "undefined") {
    window.mobileReportsApp = mobileReportsApp;
  }

  return mobileReportsApp;
})();
