window.TimePickerDefaultSettings = {
  format: 'hh:mm A',
  time: true,
  shortTime: true,
  date: false,
};

(function () {
  const dropInCoursesApp = function () {
    let courses = [];

    const processSelectResults = (labelMethod, data) => {
      if (data.data[0]?.sides) courses = data.data;

      return {
        results: data.data.map(function (elem) {
          return { id: elem.id, text: elem[labelMethod] };
        }),
      };
    };

    const bindSelect = (elem, url, labelMethod, data, onChange) => {
      let options = {
        ajax: {
          url,
          delay: 400,
          dataType: 'json',
          processResults: function (data) {
            return processSelectResults(labelMethod, data);
          },
        },
      };
      if (data) options = { data };

      elem.select2(options).on('change', onChange);
      if (!url) elem.trigger('change');
    };

    const bindChildrenSelect = (elem, options, onChange) => {
      const selectOptions = options.map((elem) => ({
        id: elem.id,
        text: elem.name,
      }));
      if (elem.data('select2')) elem.select2('destroy').find('option').remove();

      bindSelect(elem, null, null, selectOptions, onChange);
    };

    const bindSide = (sides) => {
      bindChildrenSelect($('#side_id'), sides, function () {
        const id = $(this).val();
        const side = sides.find((elem) => elem.id === id);
        if (!side) return;

        bindChildrenSelect($('#men_tee_id'), side.tees, () => {});
        bindChildrenSelect($('#women_tee_id'), side.tees, () => {});
      });
    };

    const bindCourse = () => {
      bindSelect($('#course_id'), '/courses', 'name', null, function () {
        const id = $(this).val();
        const course = courses.find((elem) => elem.id === id);

        let sides = course?.sides || window.sides;
        const side_holes =
          gon.league_holes === 9 ? 'nine_holes' : 'eighteen_holes';
        sides = sides.filter((side) => side.side_holes === side_holes);

        bindSide(sides);
        $('.side-options').removeClass('d-none');
      });
    };

    const bindDateInputs = () => {
      // $.extend($.fn.pickadate.defaults, { format: 'mm/dd/yyyy', clear: false });
      // $('#round_date').mask('00/00/0000').pickadate();
      // $("[name*='times[']").bootstrapMaterialDatePicker(
      //   window.TimePickerDefaultSettings
      // );
    };

    const bindSelectTees = () => {
      $(document).on('change', '#men_tee_id, #women_tee_id', function () {
        const men_tee_id = $('#men_tee_id').val();
        const women_tee_id = $('#women_tee_id').val();
        const links = ['.auto-configure-shotgun', '.auto-configure-tee-times'];
        for (var i = 0; i < links.length; i++) {
          const url = `${$(links[i]).attr(
            'data-href'
          )}&men_tee_id=${men_tee_id}&women_tee_id=${women_tee_id}`;
          $(links[i]).attr('href', url);
        }
      });
    };

    $(document).on('turbolinks:load', () => {
      if (
        !$('body').hasClass('controller-leagues-round_types-drop_ins-matches')
      )
        return;

      bindCourse();
      bindSide(window.course?.sides || []);
      bindDateInputs();
      bindSelectTees();

      $('#men_tee_id, #women_tee_id').trigger('change');

      if (!$('#men_tee_id').val() || $('#men_tee_id').val() === '')
        $('.side-options').addClass('d-none');
      else $('.side-options select:not(#course_id)').select2();

      courses = window.course ? [window.course] : [];
    });
  };

  if (typeof window !== 'undefined') {
    window.dropInCoursesApp = dropInCoursesApp();
  }
})();
