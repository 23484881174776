(function () {
  const mobileScoringWebAppInitApp = function () {
    const actScore = function () {
      const holePar = $(this).parent().find('input').attr('data-hole-par');
      let inputValue = $(this).parent().find('input').val();
      if (inputValue.toString() === 'X') inputValue = holePar;

      let value = parseFloat(inputValue);
      if (isNaN(value)) value = parseFloat(holePar);

      const newValue = $(this).hasClass('increase-score')
        ? value + 1
        : value - 1;
      if (newValue < 1) return;

      $(this).parent().find('input').val(newValue);
    };

    const saveScorings = function () {
      $('.mobile-scoring-modal .score-input').each(function () {
        // const teamId = $(this).attr('data-team-id');
        // const competitorId = $(this).attr('data-competitor-id');
        // const holeId = $(this).attr('data-hole-id');
        // const value = $(this).val();
        // const inputSelector = `[name='scorings[${teamId}][${competitorId}][${holeId}]']`;
        // $(inputSelector).val(value);
        // scoreWrapper.find('span').text(value);
        // const scoreWrapper = $(inputSelector)
        //   .parent()
        //   .find('a .mobile-app-score div');
        // const par = parseInt(scoreWrapper.attr('data-hole-par'));
        // const parDiff = value === 'X' ? parseInt(value) - par : 0;
        // scoreWrapper.attr('data-par-diff', parDiff);
      });
    };

    const init = () => {
      $(document).on('click', '.decrease-score, .increase-score', actScore);

      $(document).on('click', '.set-as-missing', function () {
        $(this).parent().parent().find('input.score-input').val('X');
      });

      $(document).on(
        'click',
        '.mobile-scoring-modal #save-scores',
        saveScorings
      );
    };

    $(document).on('turbolinks:load', function () {
      if (
        !$('body')
          .attr('class')
          .includes('controller-leagues-round_types-mobile_scorings')
        && !$('body')
          .attr('class')
          .includes('controller-outings-webapp-scores')
      )
        return;

      init();
    });
  };

  if (typeof window !== 'undefined') {
    window.mobileScoringWebAppInitApp = mobileScoringWebAppInitApp();
  }

  return mobileScoringWebAppInitApp;
})();
