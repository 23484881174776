$(document).on('turbolinks:load', function () {
  if (
    $('body').hasClass('controller-courses-courses') ||
    ($('body').hasClass('controller-courses-sides') &&
      $('body').hasClass('action-update_holes'))
  ) {
    $('.filter-by-course')
      .select2({
        ajax: {
          url: '/courses',
          delay: 400,
          dataType: 'json',
          processResults: function (data) {
            return {
              results: data.data.map(function (elem) {
                return { id: elem.id, text: elem.name };
              }),
            };
          },
        },
      })
      .on('change', function () {
        window.location.href = '/courses/' + $(this).val();
      });

    $('.side-select').on('change', function () {
      window.location.href =
        '/courses/' + $(this).data('course-id') + '?side_id=' + $(this).val();
    });

    $('.duplicate-side').on('click', function (e) {
      e.preventDefault();

      var url = $(this).attr('href');
      let sideName;
      window.swalWithBootstrapButtons
        .fire({
          title: 'Enter with the new side name',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Duplicate',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          preConfirm: (result) => {
            if (result && result.length > 0) return (sideName = result);

            Swal.showValidationMessage('Please enter the name of new side.');
          },
          allowOutsideClick: () => false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            $.ajax({
              url,
              data: { side_name: sideName },
              method: 'POST',
              success: (data) =>
                (window.location.href = `/courses/${gon.course_id}?side_id=${data.side_id}`),
            });
          }
        });
    });
  }
});

require('./tees');
require('./contributions');
require('./manage_league_admins');
require('./form');
