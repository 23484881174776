(function () {
  const roundTypesAutoTeeTimesApp = function (form) {
    const { fields } = form;
    const { first_time, interval_one, interval_two, groups_per_time, first_hole } = fields;
    const timeForAll = fields.time_for_all === "1";

    const setTime = (elements, time) => {
      // if (!$('body').attr('class').includes('drop_ins'))
      //   return elements.val(time.format('hh:mm A'));

      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];

        $(element).find("select").addClass("prevent-open-next");

        const hour = time.format("hh");
        const minute = time.format("mm");
        const meridiem = time.format("A");
        $(element).find("[name*=hour]").val(hour).trigger("change");
        $(element).find("[name*=minute]").val(minute).trigger("change");
        $(element).find("[name*=meridiem]").val(meridiem).trigger("change");

        setTimeout(() => $(element).find("select").removeClass("prevent-open-next"), 1000);
      }
    };

    const closeModal = () => $("#modal").modal("hide");

    const timesTarget = () => {
      return "[data-match-id] .time-inputs";
    };

    const firstDateTime = moment(`10/10/2020 ${first_time}`);
    if (timeForAll) {
      setTime($(timesTarget()), firstDateTime);
      return closeModal();
    }

    const intervals = [interval_one];
    if (interval_two !== "") intervals.push(interval_two);

    const chunks = window.sliceIntoChunks($(timesTarget()).toArray(), parseInt(groups_per_time));
    let dateTime = firstDateTime;
    for (let i = 0; i < chunks.length; i++) {
      let chunk = chunks[i];
      if (intervals.length === 1) setTime($(chunk), dateTime);

      let interval = intervals.length > 0 ? intervals[(i - 1) % intervals.length] : intervals[0];
      interval = parseInt(interval);
      dateTime = dateTime.add(interval, "minutes");
      if (intervals.length > 1) setTime($(chunk), dateTime);
    }

    $("[data-match-id] [name*='initial_hole']").val(first_hole);

    closeModal();
  };

  if (typeof window !== "undefined") {
    window.roundTypesAutoTeeTimesApp = roundTypesAutoTeeTimesApp;
  }
})();
