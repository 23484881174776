(function () {
  const reportsCoursePairings = function () {
    const init = () => {
      $('.course-pairings-date-input')
        .mask('00/00/0000')
        .pickadate({
          formatSubmit: 'yyyy/mm/dd',
          format: 'mm/dd/yyyy',
          hiddenName: true,
          onClose: () => {
            $(
              $('.course-pairings-date-input')
                .parent()
                .find('input[type=hidden]')[0].form
            ).trigger('submit');
          },
        });

      $('#report_course_id, [name*=league_ids]').on('change', function (e) {
        $(e.target.form).trigger('submit');
      });
    };

    $(document).on('turbolinks:load', () => {
      if ($('body').hasClass('controller-reports-course_pairings')) init();
    });
  };

  if (typeof window !== 'undefined') {
    window.reportsCoursePairings = reportsCoursePairings();
  }

  return reportsCoursePairings;
})();
