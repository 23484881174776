(function () {
  const roundTypesAutoShotgunApp = function (form) {
    const letters = ["A", "B", "C", "D", "E", "F", "G", "H"];

    const { fields } = form;
    let { groups_per_hole, holes, first_time } = fields;

    holes = holes.map((hole) => parseInt(hole));

    const closeModal = () => $("#modal").modal("hide");

    const groupSelector = "[data-match-id] [name*='initial_hole']";

    const holesIndex = {};

    const firstDateTime = moment.utc(first_time);
    const hour = firstDateTime.format("hh");
    const minute = firstDateTime.format("mm");
    const meridiem = firstDateTime.format("A");

    const removeUnnecessaryLetters = () => {
      $(groupSelector).each(function () {
        const hole = parseInt($(this).val());
        if (holesIndex[hole] === 0) {
          $(this).parent().parent().find("[name*=rotation]").val("").trigger("change");
        }
      });
    };

    const getHoleOcurrences = (hole) => {
      holesIndex[hole] = holesIndex[hole] != null ? holesIndex[hole] + 1 : 0;
      return letters[holesIndex[hole]];
    };

    $(groupSelector).val("");
    const chunks = window.sliceIntoChunks($(groupSelector).toArray(), parseInt(groups_per_hole));

    for (let i = 0; i < chunks.length; i++) {
      const index = i < holes.length ? i : i % holes.length;
      const hole = holes[index];
      const chunk = chunks[i];

      $(chunk).val(hole.toString()).trigger("change");
      const rotation = getHoleOcurrences(hole);
      for (let j = 0; j < chunk.length; j++) {
        $(chunk[j]).parent().parent().find("[name*=rotation]").val(rotation).trigger("change");

        const element = $(chunk[j]).parent().parent();
        $(element).find("select").addClass("prevent-open-next");

        $(element).find("[name*=hour]").val(hour).trigger("change");
        $(element).find("[name*=minute]").val(minute).trigger("change");
        $(element).find("[name*=meridiem]").val(meridiem).trigger("change");

        setTimeout(() => $(element).find("select").removeClass("prevent-open-next"), 1000);
      }
    }

    removeUnnecessaryLetters();

    closeModal();
  };

  if (typeof window !== "undefined") {
    window.roundTypesAutoShotgunApp = roundTypesAutoShotgunApp;
  }
})();
