window.sliceIntoChunks = function(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

$(document).on('click', '.wizard-link span', function() {
  window.location.href = $(this).parent().attr('href');
});

$(document).on('click', '.wizard .delete-round', function(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  const url = $(this).attr('href');

  window.swalConfirm.fire({
    confirmButtonText: 'Yes, delete the round'
  }).then(result => {
    if (!result.isConfirmed) return;

    $.ajax({ type: 'DELETE', url });
  });
});

$(document).on('change', '.image-input', function(event) {
  if(event.target.files.length > 0){
    var src = URL.createObjectURL(event.target.files[0]);
    var preview = $(this).parent().parent().parent().find('.input-preview')[0];
    preview.src = src;
  }
});
