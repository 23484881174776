$(document).on('turbolinks:load', function(){
  $('[data-unfinished-feature-message]').on('click', function() {
    window.swalWithBootstrapButtons.fire({
      title: $(this).data('unfinished-feature-title'),
      html: $(this).data('unfinished-feature-message'),
      imageUrl: $(this).data('unfinished-feature-icon'),
      showCancelButton: false,
      confirmButtonText: 'Close'
    }).then(function() {
      $('[data-unfinished-feature-message]').removeClass('active').parent().removeClass('selected');
    });
  });
});
