$.fn.modal.Constructor.prototype._enforceFocus = function() {};

(function () {
  const testEmailTarget = '.test-email-link';
  const testEmailValue = '.test_email'
  const userIdsInput = '.user_ids';

  const InviteLeaguePlayersFormApp = function () {
    const bindTestEmail = function(e) {
      window.stopEvent(e);

      $.post({
        url: `/leagues/${gon.league_id}/players/invites/send_test_email`,
        method: 'POST',
        data: $(testEmailTarget).closest('form').serializeArray(),
        success: response => window.swalConfirm.fire({
          icon: 'success',
          title: 'Test email sent',
          text: response.message,
          showCancelButton: 'false',
          confirmButtonText: 'Close'
        })
      });
      // let email = gon.current_user_email;
      // $(userIdsInput).val(null);
      // $(testEmailValue).val(email);
      // $(testEmailTarget).parent().find('[type=submit]').trigger('click');
    };

    $(testEmailTarget).on('click', bindTestEmail);
    $(userIdsInput).val(JSON.stringify(window.InviteLeaguePlayersListApp.playerIds));
  };

  if (typeof window !== "undefined") {
    window.InviteLeaguePlayersFormApp = InviteLeaguePlayersFormApp;
  }

  return InviteLeaguePlayersFormApp;
})();
