(function () {
  const leaguePaymentsCourseAdmin = function () {
    const paymentTypeSelector = '[name="league[payment_type]"]';
    const payAllSelector = '.pay-all-check';
    const contributionValueInput = '.contribution-value:visible input';
    const wrapperSelector = '.course-admin-payments';
    const payButtonSelector = '.pay-button';

    const bindPaymentTypes = () => {
      $(paymentTypeSelector).on('change', (e) => {
        $(`.pane.pane-${e.target.value}`).removeClass('d-none');
        $(`.pane:not(.pane-${e.target.value})`).addClass('d-none');
      }).find(':checked').trigger('change');
    };

    const bindPayAllCheck = () => {
      $(payAllSelector).on('change', (e) => {
        const payAll = $(e.target).prop('checked');
        const target = $(contributionValueInput).parent().parent();
        payAll ? target.addClass('visibility-hidden') : target.removeClass('visibility-hidden')
      });
    };

    const contributionPerLeague = () => {
      const payAll = $(payAllSelector).prop('checked');
      if (payAll) return 'Pay by all league';

      if (!$(contributionValueInput).val()) return null;

      return `$${$(contributionValueInput).val()} per league`;
    };

    const getContribute = () => {
      if ($(`${paymentTypeSelector}:checked`).val() === 'league') return contributionPerLeague();

      if (!$(contributionValueInput).val()) return null;

      return `$${$(contributionValueInput).val()} per player`;
    };

    const bindPayButton = () => {
      $(payButtonSelector).on('click', (e) => {
        if (!getContribute()) return false;

        const form = $(e.target.form)
        const text = `Your contribution will be: ${getContribute()}`;

        window.swalConfirm.fire({
          text,
          confirmButtonText: 'Yes, contribute!',
        }).then(result => {
          if (result.isConfirmed) form.trigger('submit');
        });
      });
    };

    const init = () => {
      bindPaymentTypes();
      bindPayAllCheck();
      bindPayButton();
      $(`${paymentTypeSelector}[value=${gon.contribution_type}]`).prop('checked', true)
                                                                 .trigger('change');
    };

    $(document).on('turbolinks:load', () => {
      if ($('body').hasClass('controller-payments-payments') && $(wrapperSelector).length > 0)
        init();
    });
  };

  if (typeof window !== "undefined") {
    window.leaguePaymentsCourseAdmin = leaguePaymentsCourseAdmin();
  }
})();
