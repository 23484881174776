$(document).on('turbolinks:load', function(){
  $('.dashboard-filter-by-course').select2({
    ajax: {
      url: '/courses?dashboard_select2=true',
      dataType: 'json',
      delay: 400,
      processResults: function (data) {
        return {
          results: data.data.map(function(elem) {
            return { id: elem.id, text: elem.name }
          })
        };
      }
    }
  }).on('change', function(){
    window.location.href = '/dashboards/courses/' + $(this).val();
  });

  $('.dashboard-filter-by-league').select2().on('change', function(){
    window.location.href =   '/dashboards/courses/'
                           + $(this).data('course-id')
                           + '/leagues/'
                           + $(this).val();
  });

});