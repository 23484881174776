(function () {
  const LeagueCustomTeesApp = function () {
    const askNewTeeName = (duplicateTeeId) => {
      let teeName;
      window.swalWithBootstrapButtons
        .fire({
          title: 'Enter a tee name',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Create new tee',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          preConfirm: (result) => {
            if (result && result.length > 0) return (teeName = result);

            Swal.showValidationMessage('Please enter the name of new tee.');
          },
          allowOutsideClick: () => false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            $.ajax({
              url: `/leagues/${gon.league_id}/custom_tees?side_id=${gon.side_id}`,
              data: { tee_name: teeName, duplicate_tee_id: duplicateTeeId },
              method: 'POST',
            });
          }
        });
    };

    const bindDuplicate = () => {
      $('.duplicate-tee').on('click', function (e) {
        e.preventDefault();
        if (!$('#duplicate_tee_id').val())
          return window.swalWithBootstrapButtons.fire({
            text: 'Please select a tee to duplicate.',
            icon: 'warning',
          });

        askNewTeeName($('#duplicate_tee_id').val());
      });
    };

    const bindNew = () => {
      $('.new-tee').on('click', function (e) {
        e.preventDefault();

        askNewTeeName();
      });
    };

    $(document).on('turbolinks:load', () => {
      if (!$('body').hasClass('controller-leagues-custom_tees')) return;

      bindDuplicate();
      bindNew();
    });
  };

  if (typeof window !== 'undefined') {
    window.LeagueCustomTeesApp = LeagueCustomTeesApp();
  }

  return LeagueCustomTeesApp;
})();
