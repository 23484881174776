playersTable = null;
$(document).on('turbolinks:load', function(){
  $('.request-subs-pagination .page-link').attr('data-turbolinks', 'false');

  playersTable = $('#subs-datatable').DataTable({
    dom: '<"top"Qf>rt<"bottom"lip><"clear">',
    serverSide: true,
    searching: true,
    pageLength: 100,
    ajax: {
      url: $('#subs-datatable').data('source'),
    },
    columns: [
      { data: 'formal_name', className: 'text-primary' },
      { data: 'phone', className: 'text-primary' },
      { data: 'email', className: 'text-primary' },
      {
        data: function(data){
          var classes = 'mdi '
          classes += data.verified === true ? 'mdi-check text-success' : 'mdi-close text-danger'
          return $('<i>').addClass(classes)[0].outerHTML;
        },
        className: 'text-center'
      },
      {
        data: function(data){
          return Mustache.render(
            $('#mustache_player_actions').html(),
            { player_id: data.id }
          );
        },
        className: 'text-right'
      }
    ],
  });

  $('#import_csv').on('change', function(){
    var file_path_splited = $(this).val().split('\\')
    $('label[for="import_csv"]').html(file_path_splited[file_path_splited.length - 1]);
  });

  if ($('#scrollable-modal').length > 0)
    $('#scrollable-modal').modal('show');
});

window.subsScreen = true;
require('../league_settings/players/remove_player_from_league');
require('../league_settings/players/add_players_modal');