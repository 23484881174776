(function () {
  const gamePlayersClass = 'js-game-players';
  const dragulaWrapper = '.js-sort-container';
  const draguleItem = '.player';
  const inputSelector = '[data-id]';
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

  const updateSort = () => {
    $(dragulaWrapper).find(inputSelector).each((_, el) => {
      $(el).parent().find('span').text('');
      $(el).val('-1');
    });

    $(`.${gamePlayersClass}`).find(inputSelector).each((index, el) => {
      $(el).parent().find('span').text(`${letters[index]} - `);
      $(el).val(index);
    });
  }

  const sortMatchPlayersApp = function () {
    const init = () => {
      dragula($(dragulaWrapper).toArray(), {
        accepts: function (source, target,) {
          if ($(source).parent().hasClass(gamePlayersClass) && $(target).hasClass(gamePlayersClass))
            return true;

          if (!$(target).hasClass(gamePlayersClass)) return true;

          return $(target).find(draguleItem).length < gon.league_players_each_week;
        }
      }).on('drop', updateSort);
    };

    return init();
  };

  if (typeof window !== "undefined") {
    window.sortMatchPlayersApp = sortMatchPlayersApp;
  }
})();
