(function () {
  const inputTarget = '.scores-input input';

  const kioskScores = function () {
    const focusNextInput = (elem) => {
      let nextTd = $(elem).closest('.scores-input').next();

      if (
        nextTd.hasClass('in') ||
        nextTd.hasClass('out') ||
        nextTd.hasClass('total')
      )
        nextTd = nextTd.next();
      if (
        nextTd.hasClass('in') ||
        nextTd.hasClass('out') ||
        nextTd.hasClass('total')
      )
        nextTd = nextTd.next();

      if (nextTd.length === 0)
        nextTd = $(elem)
          .closest('.scores-input')
          .parent()
          .next('tr')
          .find('.scores-input')
          .first();

      if (nextTd.length === 0)
        return $('input[type="submit"]').trigger('focus');

      nextTd.find('input').trigger('focus');
    };

    const calculateParDiff = (elem) => {
      const parDiffElem = $(elem)
        .closest('.scores-input')
        .find('[data-par-diff]');
      const score = parseInt($(elem).val());
      const holePar = parseInt(parDiffElem.data('hole-par'));

      let parDiff = score - holePar;
      if (parDiff > 2) parDiff = 2;
      else if (parDiff < -2) parDiff = -2;

      parDiffElem.attr('data-par-diff', parDiff === 0 ? '' : parDiff);
    };

    const updateTotals = () => {
      $('.competitor-line').each(function () {
        let totalSum = 0;
        let inSum = 0;
        let outSum = 0;

        const inputs = $(this).find('input').toArray();
        for (let i = 0; i < inputs.length; i++) {
          const elem = inputs[i];
          if (i < 9) outSum += parseInt($(elem).val()) || 0;
          else inSum += parseInt($(elem).val()) || 0;
        }
        totalSum = inSum + outSum;

        $(this).find('.in').text(inSum);
        $(this).find('.out').text(outSum);
        $(this).find('.total').text(totalSum);
      });
    };

    const bindInput = () => {
      $(document).on('keyup', inputTarget, function (e) {
        const integerValue = parseInt($(this).val());

        if (!integerValue || integerValue === 0) $(this).val('');

        if (integerValue >= 20) {
          $(this).val($(this).val()[1]);
        }

        calculateParDiff(this);

        if (integerValue > 1) {
          focusNextInput(this);
        }

        updateTotals();
      });

      $(document).on('focus', inputTarget, function () {
        $(this).trigger('select');
      });
    };

    const bindOpenInput = () => {
      $('.scores-input').on('click', function () {
        $(this).find('input').trigger('focus');
      });
    };

    const bindSubmitButton = () => {
      if ($('.btn-primary[type=submit]').length === 0) return;

      $($('.btn-primary[type=submit]')[0].form).on('submit', function () {
        $('.generic-loader').removeClass('d-none');
      });
    };

    const bindSelectCompetitor = () => {
      $('select.competitor-id').on('change', function () {
        if ($(this).val() && $(this).val() !== '')
          return (window.location.href = `/leagues/${gon.league_id}/kiosks/${$(
            this
          ).val()}/edit?round_id=${gon.round_id}`);

        window.location.href = `/leagues/${gon.league_id}/kiosks?round_id=${gon.round_id}`;
      });
    };

    const bindSelectRound = () => {
      $('select.round-id').on('change', function () {
        if (!$(this).val() || $(this).val() === '') return;

        if (!gon.competitor_id)
          return (window.location.href = `/leagues/${
            gon.league_id
          }/kiosks?round_id=${$(this).val()}`);

        return (window.location.href = `/leagues/${gon.league_id}/kiosks/${
          gon.competitor_id
        }/edit?round_id=${$(this).val()}`);
      });

      $('button.go-to-round').on('click', () =>
        $('select.round-id').trigger('change')
      );
    };

    const hideSidebar = () => {
      setTimeout(() => $('.sidebartoggler').trigger('click'), 200);
    };

    const bindClearScores = () => {
      $('.clear-scores').on('click', function (e) {
        e.preventDefault();

        window.swalConfirm.fire().then((result) => {
          if (!result.isConfirmed) return;

          $.ajax({
            url: $(this).attr('href'),
            method: 'DELETE',
            success: () => window.location.reload(),
          });
        });
      });
    };

    $(document).on('turbolinks:load', () => {
      if (
        !$('body').hasClass('controller-scorings-kiosks') &&
        !$('body').hasClass('controller-players-scores')
      )
        return;

      bindInput();
      bindOpenInput();
      updateTotals();
      bindSubmitButton();
      bindSelectCompetitor();
      bindSelectRound();
      hideSidebar();
      bindClearScores();

      if ($('body').hasClass('controller-players-scores'))
        $(inputTarget).first().trigger('focus');
    });
  };

  if (typeof window !== 'undefined') {
    window.kioskScores = kioskScores();
  }
})();
