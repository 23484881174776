(function () {
  const selectSelector = ".select2-ajax";
  const minimumInputLength = 3;

  const select2Ajax = function () {
    const bindselect2Ajax = function (selector) {
      selector = selector || selectSelector;
      $(selector).each((_, elem) => {
        const data = $(elem).data();
        if (!data.select2AjaxUrl) return;

        $(elem).select2({
          minimumInputLength,
          templateSelection: (data) =>
            $(elem).attr("data-original-text") ? data.text : data.text.split(" - ")[0],
          ajax: {
            url: data.select2AjaxUrl,
            delay: 400,
            dataType: "json",
            processResults: function (response) {
              return {
                results: response.data.map(function (elem) {
                  return {
                    ...elem,
                    id: elem[data.select2AjaxId],
                    text: elem[data.select2AjaxText],
                  };
                }),
              };
            },
          },
        });
      });
    };

    $(document).on("turbolinks:load", () => bindselect2Ajax(null));

    return { bindselect2Ajax };
  };

  if (typeof window !== "undefined") {
    window.select2Ajax = select2Ajax();
  }
})();
