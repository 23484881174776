(function () {
  const selectSelector = '.league-admins-modal .search-user';

  const courseManageLeagueAdmins = function () {
    const init = () => {
      $(document).on('change', selectSelector, function(){
        if ($(this).select2('data').length > 0){
          var data = $(this).select2('data')[0];
          var row = $(this).parent().parent().parent();
          row.find('.first_name input').val(data.first_name);
          row.find('.last_name input').val(data.last_name);
          row.find('.email input').val(data.email);
          row.find('.phone input').val(data.phone);
          row.find('.gender select').val(data.gender).trigger('change');
        }
      });
    };

    $(document).on('turbolinks:load', () => {
      init();
    });

    return { init };
  };

  if (typeof window !== "undefined") {
    window.courseManageLeagueAdmins = courseManageLeagueAdmins();
  }
})();
