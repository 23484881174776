require('./courses.js');
require('./teams.js');
require('../../../outings/common.js');
require('../../../outings/events/auto_configurations/shotgun.js');
require('../../../outings/events/auto_configurations/tee_times.js');

(function () {
  const dropInsApp = function () {
    const bindFiltersForm = () => {
      $('.round-select select').on('change', function (e) {
        window.location.href = $(this.form)
          .attr('action')
          .replace(':round_id', $(this).val());
      });
    };

    const init = function () {
      if (
        !$('body').hasClass('controller-league_settings-pairings-pairings') &&
        !$('body').hasClass('controller-leagues-round_types-drop_ins-teams') &&
        !$('body').hasClass('controller-leagues-round_types-drop_ins-matches')
      )
        return;

      bindFiltersForm();
    };

    $(document).on('turbolinks:load', init);
  };

  if (typeof window !== 'undefined') {
    window.dropInsApp = dropInsApp();
  }

  return dropInsApp;
})();
