$(document).on('turbolinks:load', function(){
  window.pairingsTable = $('#pairings-dashboard').DataTable({
    info: false,
    lengthMenu: [2, 4, 10, 25],
    pageLength: 10,
    ajax: {
      url: '/leagues/' + gon.league_id + '/matches',
    },
    columns: [
      {
        data: function(data){
          return Mustache.render(
            $('#mustache_match_row').html(),
            window.getMatch(data)
          );
        }
      },
    ]
  }).on('draw.dt', function(){
    $("[data-show=true]").prop('checked', false);
    $("[data-show=false]").prop('checked', true);
    setTimeout(function() {
      $('#pairings-dashboard .missing a').removeAttr('href').removeAttr('data-remote');
      if (window.pairingsTable.matchId) {
        $(`div[data-match-id="${window.pairingsTable.matchId}"]`).trigger('click');
        window.pairingsTable.matchId = null;
        $('#overrides-modal').modal('hide');
      }
    }, 500);
  });

  $('#round_date').on('change', function(){
    var defaultUrl = '/leagues/' + gon.league_id + '/matches';
    var url = $(this).val() ? defaultUrl + '?by_round=' + $(this).val() : defaultUrl
    window.pairingsTable.ajax.url(url);
    window.pairingsTable.draw();
    if ($(this).val() === '')
      $('.edit-round-settings').hide()
    else if (window.edit_round_type_link) {
      var round_type_link = window.edit_round_type_link.replace(':round_type_id', $(this).val());
      $('.edit-round-settings').show().attr('href', round_type_link);

      var matches_report_link = window.matches_report_link.replace('%3Around_type_id', $(this).val());
      $('.round-report').attr('href', matches_report_link);

      var override_handicap_link = window.override_handicap_link.replace(':round_type_id', $(this).val());
      $('.round-edit-hdcp').attr('href', override_handicap_link);
    }
  }).trigger('change');
});

$(document).on('click', '.toggle-match', function(){
  $(this).next('.match-teams').toggleClass('d-none').toggleClass('d-flex');
  $(this).find('i').toggleClass('mdi-chevron-down').toggleClass('mdi-chevron-up');
});

$(document).on('change', '.change-player-show-dashboard', function(){
  var show = !$(this).prop('checked');
  $('.league-dashboard-loader').removeClass('d-none');
  var url = $(this).data('update-url').replace('_MATCHID_', $(this).data('match-id'))
                                      .replace('_PLAYERID_', $(this).data('player-id'))
                                      .replace('_TEAMID_', $(this).data('team-id'));
  $.ajax({
    type: 'PUT',
    url,
    data: { match_player_configuration: { show } },
  });
});