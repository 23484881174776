(function () {
  const leagueCardGroupsApp = function () {
    const bindTimeInputs = () => {
      $('.group-date').timepicker({
        timeFormat: 'h:mm p',
        interval: 30,
        dropdown: true,
        scrollbar: true,
        dynamic: false,
      });
    };

    const bindRestoreGroups = () => {
      $('.restore-default-groups').on('click', function (e) {
        e.preventDefault();

        const url = $(this).attr('href');
        window.swalConfirm
          .fire({
            text: 'All changes to this rounds card group you have made will be removed.',
            confirmButtonText: 'Yes, reset to my current pairings',
          })
          .then((result) => {
            if (!result.isConfirmed) return;

            $.ajax({ method: 'DELETE', url });
          });
      });
    };

    const bindMoveAllToLeft = () => {
      $('.move-all-to-left').on('click', function (e) {
        e.preventDefault();

        window.swalConfirm.fire().then((result) => {
          if (!result.isConfirmed) return;

          $('.match-group input.team-input').val('');
          $('.match-group').each(function () {
            $('.needing-group').append($(this)[0].innerHTML);
            $(this)[0].innerHTML = '';
          });
        });
      });
    };

    const bindActions = () => {
      bindRestoreGroups();
      bindMoveAllToLeft();
    };

    const bindDragAndDrop = () => {
      dragula($('.needing-group, .match-group').toArray(), {}).on(
        'drop',
        function (el, target) {
          if ($(target).hasClass('needing-group'))
            $(el).find('input.team-input').val('');
          else
            $(el).find('input.team-input').val($(target).attr('data-group-id'));
        }
      );
    };

    $(document).on('turbolinks:load', function () {
      if (!$('body').hasClass('controller-leagues-round_types-card_groups'))
        return;

      bindTimeInputs();
      bindDragAndDrop();
      bindActions();
    });
  };

  if (typeof window !== 'undefined') {
    window.leagueCardGroupsApp = leagueCardGroupsApp();
  }

  // return leagueCardGroupsApp();
})();
