$(document).on('click', '.remove-player-from-league', function(e){
  e.preventDefault();
  var scope = this;
  var url = $(this).data('url');
  var playerName = $(this).parent().parent().find('td:first-child').html();

  window.swalWithBootstrapButtons.fire({
    title: 'Removing ' + playerName,
    text: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true
  }).then(result => {
    if (result.isConfirmed)
      $.ajax({
        type: 'DELETE',
        url: url,
        success: function(response){
          if (response.errors) {
            window.swalWithBootstrapButtons.fire({
              title: 'Error',
              text: response.errors,
              icon: 'error',
              confirmButtonText: 'Close',
            })
            return;
          }
          $(scope).parent().parent().remove();
          playersTable.draw();
        }
      });
  })
})