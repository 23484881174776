window.unsavedChanges = false;
window.currentScore = null;

function formatSelect2WithCheck(state) {
  if (!$(state.element).data("icon")) {
    return state.text;
  }

  var $state = $(`<span>${$(state.element).data("icon")} ${state.text}</span>`);
  return $state;
}

$(document).on("turbolinks:load", function () {
  if (!$("body").hasClass("controller-scorings-scores")) return;

  if (gon.round_type_id && gon.round_type_id !== "") {
    $.get(`/leagues/${gon.league_id}/round_types/${gon.round_type_id}/teams.js`);
  }

  $(document).on(
    "change",
    ".controller-scorings-scores.action-index .score-filters select",
    function (e) {
      if ($(e.target).attr("id") === "round_type_id") {
        $(".score-filters #match_id").remove();
        $(".score-filters .select2-selection.select2-selection--single").addClass("bg-transparent");
        $(".score-filters").append(
          $("<input>")
            .attr("type", "hidden")
            .attr("name", "match_id")
            .attr("value", $(this).find("option:selected").attr("data-next-match-id"))
        );
      }

      $(e.target.form).trigger("submit");
    }
  );

  var matchesMatcher = function (params, data) {
    if ($.trim(params.term) === "") {
      return data;
    }

    if (typeof data.text === "undefined") {
      return null;
    }

    if (
      $(data.element).attr("data-players") &&
      $(data.element)
        .attr("data-players")
        .toString()
        .toUpperCase()
        .indexOf(params.term.toUpperCase()) > -1
    ) {
      return data;
    }

    if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
      return data;
    }

    return null;
  };

  $(`.controller-scorings-scores.action-index #match_id`).select2({
    escapeMarkup: function (m) {
      return m;
    },
    matcher: matchesMatcher,
    templateResult: formatSelect2WithCheck,
    templateSelection: formatSelect2WithCheck,
  });

  var controller = ".controller-scorings-scores.action-index";
  $(`${controller} #round_type_id`).select2({
    escapeMarkup: function (m) {
      return m;
    },
    templateResult: formatSelect2WithCheck,
    templateSelection: formatSelect2WithCheck,
  });

  const clearScores = function (e) {
    e.preventDefault();
    var teamId = $(e.target).data("team-id");

    window.swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "This action will delete all scores from this team.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, I'm sure",
        reverseButtons: true,
      })
      .then(function (result) {
        if (!result.isConfirmed) return;

        $.ajax({
          type: "DELETE",
          url: `/leagues/${gon.league_id}/scores/clear_scores?team_id=${teamId}&match_id=${gon.match_id}`,
          success: function () {
            window.location.reload();
          },
        });
      });
  };

  $(document).on("click", ".toggle-score", function (e) {
    if (e.target.outerHTML.slice(0, 2) === "<i" || e.target.outerHTML.slice(0, 2) === "<a")
      return true;
    if ($(e.target).text() == "Clear scores") {
      clearScores();
    } else {
      $(".toggle-score").find("i").toggleClass("mdi-chevron-down").toggleClass("mdi-chevron-up");
      $(".toggle-score").toggleClass("hidden").next(".table-responsive").toggleClass("d-none");
    }
    e.preventDefault();
  });

  $(".clear-scores-link").on("click", clearScores);

  $(".toggle-score").first().trigger("click");

  $('[name*="scores["]').mask("Z9", {
    translation: {
      Z: {
        pattern: /[X|x|0-9]/,
        optional: true,
      },
    },
  });

  $(document).on("focus", '[name*="scores["]', function () {
    $(this).trigger("select");
  });

  $(document).on("keyup", '[name*="scores["]', function () {
    if ($(this).val() === "x") $(this).val("X");

    if (["X", "x"].includes($(this).val()) && !gon.allow_x) return $(this).val("");

    if (($(this).val() !== "X" && !parseInt($(this).val())) || parseInt($(this).val()) === 0)
      return $(this).val("");

    if (parseInt($(this).val()) >= 20) {
      $(this).val($(this).val()[1]);
    }

    var nextTd = $(this).parent().next();
    if (nextTd.hasClass("total")) {
      nextTd = nextTd.next();
      $(this).closest("table").parent().scrollLeft(200);
    }
    var nextInput = nextTd.find('input[name*="scores["]');
    var changeInput = parseInt($(this).val()) > 1 || $(this).val() === "X";

    if (changeInput && nextInput && nextInput.length > 0) {
      $(nextInput).removeClass("d-none").trigger("focus").parent().find("span").addClass("d-none");
    } else if (changeInput) {
      var next = $(this).parent().parent().next().find('input[name*="scores["]').first();
      if (next && next.length === 0)
        next = $(this)
          .closest("table")
          .parent()
          .parent()
          .parent()
          .next()
          .find('input[name*="scores["]')
          .first();
      if (next && next.length === 0) next = $("[type=submit]");
      next.removeClass("d-none").trigger("focus");
      $(this).closest("table").parent().scrollLeft(0);
    }
  });

  $(document).on("click", ".controller-scorings-scores.action-index .score-entry-td", function () {
    if (
      $(this).attr("data-round-calculated") === undefined &&
      $(this).attr("data-blind-draw") === undefined &&
      $(this).attr("data-play-against-par") === undefined
    ) {
      $(this).find("span").addClass("d-none");
      $(this).find("input").removeClass("d-none").trigger("focus");
    }
  });

  $(document).on(
    "focus",
    ".controller-scorings-scores.action-index .score-entry-td input",
    function () {
      window.currentScore = parseInt($(this).closest("td").find("input").val()) || 0;
      $(this).closest("td").find("span").addClass("d-none");
      $(this).closest("td").find("input").removeClass("d-none");
    }
  );

  $(document).on(
    "blur",
    ".controller-scorings-scores.action-index .score-entry-td input",
    function () {
      var td = $(this).closest("td");
      var par = parseInt(td.data("hole-par"));
      var score = parseInt($(this).val()) || 0;
      var parDiff = score - par;
      if ($(this).val() != "X" && $(this).val() != "") {
        parDiff = parDiff > 2 ? 2 : parDiff;
        parDiff = parDiff < -2 ? -2 : parDiff;
      }
      td.find("span[data-par-diff]").attr("data-par-diff", parDiff);
      td.find("span[data-par-diff] span").html($(this).val());

      td.find("span").removeClass("d-none");
      td.find("input").addClass("d-none");

      var input = td.find("input");
      if (input.val() === "X") {
        const value = gon[`par_${$(td).attr("data-hole-par")}`];
        if (!value) return true;

        $(input).parent().find("span span").html(`${value}X`);
        $(input).attr("data-missing-hole-score", value);
      }

      var sumOfScores = 0;
      var sumFirstNine = 0;
      var sumLastNine = 0;
      td.parent()
        .find("input:not(.change-player-show)")
        .each(function (index, input) {
          var scoreValue = parseInt($(input).val());
          var missingScore = parseInt($(input).attr("data-missing-hole-score"));
          sumOfScores += scoreValue || missingScore || 0;

          if (parseInt($(input).data("hole-name")) <= 9) {
            sumFirstNine += scoreValue || missingScore || 0;
          } else {
            sumLastNine += scoreValue || missingScore || 0;
          }

          if (td.parent().find("input:not(.change-player-show)").length === index + 1) {
            td.parent().find(".player_total").html(sumOfScores);
            if (td.parent().find(".first-nine-total").length > 0) {
              td.parent().find(".first-nine-total div").html(sumFirstNine);
              td.parent().find(".last-nine-total div").html(sumLastNine);
            }
          }
        });

      if (score !== window.currentScore && !window.unsavedChanges) window.unsavedChanges = true;

      if (gon.demo_user) return true;

      return true; // Skiping auto save
    }
  );

  $('[name*="scores["]').first().parent().find("span").addClass("d-none");
  $('[name*="scores["]').first().removeClass("d-none").trigger("focus");
});

$(document).on("select2:open", "#player_override_handicap", function () {
  if (!$(this).val() || $(this).val() === "") $(this).val("0");
});

$(document).on("select2:close", "#player_override_handicap", function () {
  if ($(this).val() === "0") $(this).trigger("change");
});

var target = ".controller-scorings-scores .calculate-round-button";
$(document).on("click", target, function (e) {
  e.preventDefault();
  const roundId = $(this).data("round-id");
  if ($(this).data("allowed") === undefined) {
    window.swalWithBootstrapButtons.fire({
      text: "You have previous rounds that have not been calculated. Please complete those rounds first.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "OK",
    });
    return;
  }

  window.swalConfirm
    .fire({
      title: "Are you sure?",
      html: `Are you sure you want to calculate ${gon.roundDateFormatted}?`,
      // html: "You are going to calculate just this round. <br>\
      //      If you want to calculate future or past rounds \
      //      please use the Calculate Round option on League Actions menu.",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
    .then(function (result) {
      if (!result.isConfirmed) return;

      var url = `/dashboards/leagues/${gon.league_id}/set_calculation`;
      $(".loader").removeClass("d-none");
      $.ajax({
        url,
        data: {
          type: "calculate",
          past_rounds: false,
          league_setting_round_type_id: roundId,
        },
        method: "POST",
      });
    });
});
