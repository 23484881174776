(function () {
  const dropInTeamsApp = function () {
    const bindDragNDrop = () => {
      const dropTeamsContainer = dragula(
        $('.players-needing-team, .team-container').toArray(),
        {}
      ).on('drop', setPlayerTeam);

      window.dropInTeamsApp = { dropTeamsContainer };
    };

    const setPlayerTeam = function (el, target) {
      var teamValue = $(target).hasClass('team-container')
        ? $(target).data('team-id')
        : '';
      $(el).find('.player-input').val(teamValue);
    };

    $(document).on('turbolinks:load', () => {
      if (
        !$('body').hasClass('controller-league_settings-pairings-pairings') &&
        !$('body').hasClass('controller-leagues-round_types-drop_ins-teams')
      )
        return;

      bindDragNDrop();
    });
  };

  if (typeof window !== 'undefined') {
    window.dropInTeamsApp = dropInTeamsApp();
  }
})();
