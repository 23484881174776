require('./league_admins.js');
require('./course_contributions.js');
require('./course_payments.js');
require('./stripe.js');

require('./players.js');

(function () {
  const addPaymentMethodLink = '.stripe-modal .add-payment-method-link';
  const payModalSelector = '.stripe-modal';
  const paymentLoader = '.payment-loader';
  const payButton = '.pay-button';
  const totalSelector = '.total';
  const modalPayButton = '.modal .pay-button';
  const isACoursePay = () => $('.contribution-value').length > 0
  const courseContributionSelector = '.contribution-value:visible input';

  const openPayModal = () => {
    if ($(totalSelector).first().text() === '0') return;

    const label = isACoursePay() ? 'CONTRIBUTE WITH' : 'PAY';
    if (!isACoursePay())
      $(modalPayButton).text(`${label} $${$(totalSelector).first().text()}`);
    else
      $(modalPayButton).text(`${label} $${$(courseContributionSelector).first().val()}`);

    clickOnPayMethod();
  };

  const bindPayButton = () => {
    $(payButton).on('click', openPayModal);
  };

  const clickOnPayMethod = () => {
    if (!isACoursePay() && $(payModalSelector).find('input:not([type=hidden])').length === 0) {
      $(paymentLoader).removeClass('d-none');
      $(payModalSelector).modal('hide');
      $.ajax({
        url: $(addPaymentMethodLink).attr('href'),
        success: () => {
          $(paymentLoader).addClass('d-none');
        }
      });
    } else {
      $(payModalSelector).modal('show');
    }
  };

  const leaguePaymentsInit = function () {
    $(document).on('turbolinks:load', () => {
      if ($('body').hasClass('controller-payments-payments'))
        bindPayButton();
    });

    return { clickOnPayMethod, openPayModal };
  };

  if (typeof window !== "undefined") {
    window.leaguePaymentsInit = leaguePaymentsInit();
  }
})();
