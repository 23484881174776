$.extend(true, $.fn.dataTable.defaults, {
  language: {
    infoFiltered: '',
    infoPostFix: '',
    sLengthMenu: 'Show _MENU_ rows per page',
  },
  serverSide: true,
  ordering: false,
  dom: '<"top">rt<"bottom"flip><"clear">',
});

$(document).on('turbolinks:load', function () {
  $('#calculate-round #uncalculate, #calculate-round #calculate').on(
    'click',
    function (e) {
      if ($(this).attr('id') === 'calculate') {
        if (!gon.system_admin) {
          var that = this;
          var form = $(that).closest('form');
          return window.swalWithBootstrapButtons
            .fire({
              title: 'Are you sure?',
              text: 'Do you want to calculate this round?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, calculate',
              cancelButtonText: 'No, cancel',
              reverseButtons: true,
            })
            .then((result) => {
              if (result.dismiss === 'backdrop' || !result.isConfirmed) return;

              $('#past-rounds').val(false);
              $('#calculate-type').val($(that).attr('id'));
              $.ajax({
                url: $(form).attr('action'),
                data: $(form).serialize(),
                method: 'POST',
              });
              $('.calculation-loader').removeClass('d-none');
            });
        }
        var that = this;
        var form = $(that).closest('form');
        return window.swalWithBootstrapButtons
          .fire({
            title: 'Do you want to calculate all rounds past this point?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, all rounds past this point',
            cancelButtonText: 'No, only this round',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.dismiss === 'backdrop') return;

            $('#past-rounds').val(result.isConfirmed);
            $('#calculate-type').val($(that).attr('id'));
            $.ajax({
              url: $(form).attr('action'),
              data: $(form).serialize(),
              method: 'POST',
            });
            $('.calculation-loader').removeClass('d-none');
          });
      }

      $('#calculate-type').val($(this).attr('id'));
      $($(this).first().form).trigger('submit');
      $('.calculation-loader').removeClass('d-none');
    }
  );

  $('.modal#calculate-round').on('shown.bs.modal', function (e) {
    $(this).find('select#league_setting_round_type_id').select2();
  });

  $('#edit-round').on('click', function (e) {
    e.preventDefault();
    var round_date = $('#round_date').val();
    if (round_date === '') round_date = $('#round_date options').first().val();
    window.location.href = `${$(this).attr(
      'href'
    )}?from_dashboard=true&round_date=${round_date}`;
  });

  $(document).on('click', '.renew-league', function (e) {
    e.preventDefault();
    var leagueName = $(this).data('league-name');
    var leagueId = $(this).data('league-id');
    var courseId = $(this).data('course-id');
    window.swalConfirm
      .fire({
        text: `You will renew the league ${leagueName}.`,
        confirmButtonText: 'Yes, renew this league!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: 'GET',
            url: `/courses/${courseId}/leagues/${leagueId}/renews/new`,
          });
        }
      });
  });

  $('option[value=' + gon.next_uncalculated_round + ']').attr(
    'selected',
    'selected'
  );

  if (gon.catch_tee_slope_and_rating) {
    window.swalConfirm
      .fire({
        title: 'Warning',
        text: 'You have tees selected that do not have USGA slope or rating in the system. Please add the correct information before you move forward',
        confirmButtonText: 'Yes, add now',
        cancelButtonText: 'No, do later',
        showCancelButton: window.usgaLeague !== true,
        allowOutsideClick: false,
      })
      .then((result) => {
        if (!result.isConfirmed) return;

        $.ajax({ url: $('#configure-tees').attr('href') });
      });
  }
});

require('./datatables/courses');
require('./datatables/leagues');
require('./datatables/admin_leagues');
require('./datatables/teams');
require('./datatables/players');
require('./datatables/match_utils');
require('./datatables/matches');
require('./leagues_calendar');
require('./bar_reports');
require('./filters');
