(function () {
  const bodyClass = "controller-leagues-replaces";
  const form = ".tab-pane form";
  const recreatePairingsInput = "#recreate-pairings";
  const loader = ".generic-loader";

  const replaceGolfersApp = function () {
    const submitForm = (target) => {
      $(loader).removeClass("d-none");
      $(target).off("submit", confirmForm);
      $(target).trigger("submit");
    };

    const bindSubmitButton = function (e) {
      $(loader).removeClass("d-none");
      // $(this).find("[type=submit").addClass("invisible");
    };

    const confirmForm = (e) => {
      e.preventDefault();
      const target = e.target;
      $(target).find("input[type=submit]").removeAttr("disabled");

      window.swalConfirm.fire().then((result) => {
        if (result.isConfirmed) {
          if (gon.vs_the_field) return submitForm(target);

          const roundDateDescription = $(
            "#leagues_replaces_golfer_start_round_id option:selected"
          ).text();
          window.swalConfirm
            .fire({
              title: "",
              text: `If you have pairings, adding a team will recreate them starting with ${roundDateDescription}? Note: If you have an odd number of teams it will simply add the new team to the opening.`,
              cancelButtonText: "Cancel",
              confirmButtonText: "Yes",
            })
            .then((result) => {
              if (!result.isConfirmed) return;

              $(recreatePairingsInput).val("true");
              submitForm(target);
            });
        }
      });
    };

    const setTeamName = () => {
      const newName = $(".league-subs")
        .toArray()
        .map((elem) => $(elem).find("option:selected").text().split(" ").slice(-1)[0])
        .filter((elem) => elem && elem !== "")
        .join("/");
      $("#leagues_replaces_add_new_team_name").val(newName);
    };

    const init = function () {
      if (!$("body").hasClass(bodyClass)) return;

      if (gon.form === "add") $(form).on("submit", confirmForm);
      else $(form).on("submit", bindSubmitButton);

      $(".league-subs")
        .parent()
        .parent()
        .parent()
        .find("a")
        .on("click", (e) => {
          window.subId = $(e.target).parent().parent().parent().find("select").attr("id");
        });

      $(".league-subs").on("change", setTeamName);
    };

    $(document).on("turbolinks:load", init);
  };

  if (typeof window !== "undefined") {
    window.replaceGolfersApp = replaceGolfersApp();
  }

  return replaceGolfersApp;
})();
