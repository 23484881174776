require('./extra_points/teams.js');
require('./extra_points/players.js');

$(document).on('turbolinks:load', function(){
  $(document).on('change', '.extra-points-input', function(){
    var competitorType = $(this).attr('data-competitor-type').toLowerCase();
    var extraPoints = gon[`${competitorType}_extra_points`];
    var elem = extraPoints.find(elem => elem.id == $(this).attr('data-competitor'));
    elem = elem || { id: $(this).attr('data-competitor'), points: 0 };
    elem.points = parseFloat($(this).val());
  });

  $('#submit-extra-points').on('click', function(){
    var url = '/leagues/' + gon.league_id + '/extra_points/' + $('#round_type_id').val();
    $('.extra-points-loader').removeClass('d-none');
    $.ajax({
      type: 'PUT',
      url: url,
      data: { players: gon.player_extra_points, teams: gon.team_extra_points },
      success: () => {
        $('.extra-points-loader').addClass('d-none');
      }
    });
  });
});
