(function () {
  const outingEventsAutoShotgunApp = function (form) {
    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

    const { fields } = form;
    let { teams_per_hole, holes } = fields;

    holes = holes.map((hole) => parseInt(hole));

    const closeModal = () => $('#modal').modal('hide');

    const groupSelector = $('body').attr('class').includes('drop_ins') ? ".teams [name*='holes[']" : ".tee-group [name*='holes[']";

    const removeUnnecessaryLetters = () => {
      $(groupSelector).each(function () {
        let hole = parseInt($(this).val());
        const sameHoles = $(groupSelector).filter(function () {
          return parseInt($(this).val()) === hole;
        });
        if (sameHoles.length === 1) $(this).val(hole);
      });
    };

    const getHoleOcurrences = (hole) => {
      let occurrences = 0;
      $(groupSelector).each(function () {
        if (parseInt($(this).val()) === hole) occurrences += 1;
      });
      return letters[occurrences];
    };

    $(groupSelector).val('');
    const chunks = window.sliceIntoChunks(
      $(groupSelector).toArray(),
      parseInt(teams_per_hole)
    );

    for (let i = 0; i < chunks.length; i++) {
      const index = i < holes.length ? i : i % holes.length;
      const hole = holes[index];
      const chunk = chunks[i];

      hole = `${hole}${getHoleOcurrences(hole)}`;

      $(chunk).val(hole);
    }

    removeUnnecessaryLetters();

    closeModal();
  };

  if (typeof window !== 'undefined') {
    window.outingEventsAutoShotgunApp = outingEventsAutoShotgunApp;
  }
})();
