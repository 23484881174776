(function () {
  const element = 'par-chart';

  const reportsPlayerDashboardParAvg = function () {
    const data = gon.par_avg.report;
    Highcharts.chart(element, {
      chart: {
        marginRight: screen.width > 768 ? 70 : 0,
        backgroundColor: 'transparent'
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: data.pars,
        lineWidth: 0
      }],
      yAxis: [{
        gridLineWidth: 0,
        allowDecimals: false,
        labels: {
          style: { color: Highcharts.getOptions().colors[1] }
        },
        title: {
          text: '',
          style: { color: Highcharts.getOptions().colors[1] }
        }
      }, {
        allowDecimals: false,
        title: {
          text: '',
          style: { color: Highcharts.getOptions().colors[0] }
        },
      labels: {
          style: { color: Highcharts.getOptions().colors[0] }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: { className: 'd-none' },
      series: [{
        name: 'Player averages',
        type: 'column',
        data: arrayToFloat(data.competitor)

      }, {
        name: 'League average',
        type: 'column',
        data: arrayToFloat(data.league)
      }]
    });
  };

  if (typeof window !== "undefined") {
    window.reportsPlayerDashboardParAvg = reportsPlayerDashboardParAvg;
  }

  return reportsPlayerDashboardParAvg;
})();
