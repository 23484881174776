(function () {
  const btnTarget = "#set-last-round-handicaps";
  const target = "[data-last-round-handicap]";
  const inputsTarget = "input.change-handicap-by-round";
  const handicapAttribute = "last-round-handicap";
  const naHdcpsTarget = ".na-hdcp";
  const indexTarget = "[name*='handicaps_index['";

  const roundTypesByHandicap = function () {
    const bindNaHdcpsChange = (e) => {
      const inputTarget = $(e.target).parent().parent().find(inputsTarget);

      if ($(e.target).prop("checked")) {
        inputTarget.val("").attr("disabled", "disabled").addClass("opacity-05");
      } else {
        inputTarget.removeAttr("disabled").removeClass("opacity-05");
      }
    };

    const setLastRoundHandicaps = () => {
      $(target).each(function () {
        $(this).val($(this).data(handicapAttribute));
      });
    };

    const indexOrHandicap = (e) => {
      const indexInput = $(e.target).attr("name").includes("_index");
      if ($(e.target).val() && $(e.target).val() !== "") {
        const parentTarget = $(e.target).parent().parent();
        const targetInput = indexInput ? "[name*='handicaps[']" : "[name*='handicaps_index[']";
        parentTarget.find(targetInput).val("");
      }
    };

    const bindInputs = () => {
      $(inputsTarget)
        .mask("###", {
          reverse: true,
          translation: {
            "#": {
              pattern: /\+|\d/,
              recursive: true,
            },
          },
          onChange: function (value, e) {
            e.target.value = value
              .replace(/(?!^)\+/g, "")
              .replace(/^,/, "")
              .replace(/^\+,/, "+");
          },
        })
        .on("change", indexOrHandicap)
        .on("keyup", indexOrHandicap);

      $(indexTarget)
        .on("wheel", function () {
          $(this).trigger("blur");
        })
        .on("change", indexOrHandicap)
        .on("keyup", indexOrHandicap)
        .on("keydown", preventSubmit);
    };

    const preventSubmit = (e) => {
      if (e.keyCode == 13) {
        e.preventDefault();
        const name = $(e.target).attr("name").split("[")[0];
        const expression = `input[name*='${name}[']:not(.na-hdcp)`;
        nextInput = $(e.target).parent().parent().next("tr").find(expression);
        if (nextInput.length === 0) nextInput = $("button[type=submit]").last();
        nextInput.trigger("focus");
        return false;
      }
    };

    $(document).on("click", btnTarget, setLastRoundHandicaps);
    $(document).on("keydown", inputsTarget, preventSubmit);
    $(document).on("change", naHdcpsTarget, bindNaHdcpsChange);
    $(document).on("turbolinks:load", bindInputs);

    $(document).on(
      "change",
      "body.controller-leagues-round_types-handicaps #show_players",
      function (e) {
        $(".loader.generic-loader").removeClass("d-none");
        window.location.href = `/leagues/${gon.league_id}/round_types/${
          gon.round_type_id
        }/handicaps?show_players=${$(this).prop("checked") ? "true" : "false"}`;
      }
    );

    $(document).on("click", ".recalculate-handicaps", function (e) {
      window.stopEvent(e);

      const url = $(this).attr("href");
      window.swalConfirm
        .fire({
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (!result.isConfirmed) return;

          $(".loader.generic-loader").removeClass("d-none");
          $.ajax({
            method: "GET",
            url,
            success: function () {
              window.location.reload();
            },
          });
        });
    });
  };

  if (typeof window !== "undefined") {
    window.roundTypesByHandicap = roundTypesByHandicap;
  }

  return roundTypesByHandicap();
})();
