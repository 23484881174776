$(document).on('turbolinks:load', function () {
  $('.controller-leagues-match_overrides .filters select').on(
    'change',
    function () {
      var url = window.formUrl
        .replace(':round_type_id', $('#round_type_id').val())
        .replace('%3Adivision_id', $('#division_id').val());
      window.location.href = url;
    }
  );

  $.extend($.fn.pickadate.defaults, { format: 'mm/dd/yyyy', clear: false });
  $('.controller-leagues-match_overrides .date').mask('00/00/0000').pickadate();
  $('.controller-leagues-match_overrides .time').bootstrapMaterialDatePicker({
    format: 'hh:mm A',
    time: true,
    shortTime: true,
    date: false,
  });

  $('.controller-leagues-match_overrides .change-side').on(
    'change',
    function () {
      var id = $(this).val();
      var side = gon.sides.find((elem) => elem.id === id);

      $(this).parent().parent().find('.men-tee-id option').remove();
      $(this).parent().parent().find('.women-tee-id option').remove();

      for (var i = 0; i < side.tees.length; i++) {
        var tee = side.tees[i];
        if (tee.parent_tee_id) continue;

        var tees = ['.women-tee-id', '.men-tee-id'];
        for (var j = 0; j < tees.length; j++) {
          const defaultTees = tees[j].includes('women')
            ? window.defaultWomenTeeIds
            : window.defaultMenTeeIds;
          var option = $('<option>').attr('value', tee.id).append(tee.name);
          if (
            $(this).parent().parent().find(tees[j]).val() === tee.id ||
            defaultTees.includes(tee.id)
          )
            $(option).attr('selected', 'selected');
          $(this).parent().parent().find(tees[j]).append(option);
        }
      }
      $(this).parent().parent().find('.women-tee-id, .men-tee-id').select2();

      // if ($(this).find('option[selected]').length > 0) {
      //   var teeId = $(this)
      //     .find(`option[value='${$(this).val()}']`)
      //     .attr('data-men-tee');
      //   $(this)
      //     .parent()
      //     .parent()
      //     .find('.men-tee-id')
      //     .val(teeId)
      //     .trigger('change');

      //   teeId = $(this)
      //     .find(`option[value='${$(this).val()}']`)
      //     .attr('data-women-tee');
      //   $(this)
      //     .parent()
      //     .parent()
      //     .find('.women-tee-id')
      //     .val(teeId)
      //     .trigger('change');
      // }
    }
  );

  $('.controller-leagues-match_overrides #shuffle-tee-times').on(
    'click',
    function (e) {
      e.preventDefault();
      window.swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: 'If you already printed the scorecards, you can have problems.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, continue!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.location.href = window.location.href
              .replace('match_overrides', 'round_types')
              .replace('edit', 'shuffle_tee_times');
          }
        });
    }
  );

  // const onDrop = function (el, target, source, sibling) {
    // setTimeout(() => {
    //   const elMatchId = $(el).attr('data-match-id');
    //   const siblingMatchId = $(sibling).attr('data-match-id');

    //   const elMatchName = $(el).find('.teams-description')[0].innerHTML;
    //   const siblingMatchName = $(sibling).find('.teams-description')[0].innerHTML;

    //   $(el).find('.teams_description').html(siblingMatchName);
    //   $(sibling).find('.teams_description').html(elMatchName);

    //   const elHTML = $(el)[0].outerHTML.replaceAll(elMatchId, siblingMatchId);
    //   const siblingHTML = $(sibling)[0].outerHTML.replaceAll(siblingMatchId, elMatchId);

    //   $(el)[0].outerHTML = siblingHTML;
    //   $(sibling)[0].outerHTML = elHTML;
    // }, 300);

    // console.log($(`[name*='match[${elMatchId}]']`));
    // console.log(el, sibling)
  // };

  // dragula([$('.controller-leagues-match_overrides tbody')[0]], {
    // moves: function (_el, _container, handle) {
    //   return handle.classList.contains('mdi-cursor-move');
    // },
  // }).on('drop', onDrop);
});
