$(document).on('turbolinks:load', function () {
  if (
    $('body').hasClass('controller-courses-courses') ||
    $('body').hasClass('controller-leagues-custom_tees') ||
    ($('body').hasClass('controller-courses-sides') &&
      $('body').hasClass('action-update_holes'))
  ) {
    $('.toggle-tee').on('click', function (e) {
      if (
        !$(e.target).hasClass('edit-tee') &&
        !$(e.target).hasClass('delete-tee')
      ) {
        $(this).parent().find('.tee-info').toggleClass('d-none');
        $(this)
          .parent()
          .find("[class*='mdi-chevron']")
          .toggleClass('mdi-chevron-down')
          .toggleClass('mdi-chevron-up');
      }
    });
    $('.toggle-tee').first().trigger('click');

    $('.delete-tee, .delete-side').on('click', function (e) {
      e.preventDefault();

      var url = $(this).attr('href');
      window.swalConfirm
        .fire({
          confirmButtonText: 'Yes, delete!',
        })
        .then((result) => {
          if (result.isConfirmed)
            $.ajax({
              url,
              method: 'DELETE',
              success: () =>
                (window.location.href = `/courses/${gon.course_id}`),
            });
        });
    });

    $('input[name*=par], input[name*=handicap], input[name*=distance]')
      .on('keyup change', function () {
        const inputs = $(this).closest('tr').find('input');
        let sum = 0;
        for (input of inputs) {
          if (!input.value) continue;
          sum += parseInt(input.value);
        }
        $(this).closest('tr').find('.total').html(sum);
      })
      .trigger('change');
  }
});
