require('./playerDashboard/currentHandicap.js');
require('./playerDashboard/parAvg.js');
require('./playerDashboard/scoreDistribution.js');

(function () {
  const reportsPlayerDashboard = function () {
    window.arrayToFloat = arr => arr.map(item => parseFloat(item));

    const init = () => {
      window.reportsPlayerDashboardCurrentHandicap();
      window.reportsPlayerDashboardParAvg();
      window.reportsPlayerDashboardScoreDistribution();
    }

    $(document).on('turbolinks:load', () => {
      if ($('body').hasClass('controller-reports-player_dashboard'))
        setTimeout(init, 1000);
    })
  };

  if (typeof window !== "undefined") {
    window.reportsPlayerDashboard = reportsPlayerDashboard();
  }

  return reportsPlayerDashboard;
})();
