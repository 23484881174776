// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

import moment from 'moment';
window.moment = moment;

import JQuery from 'jquery';
window.$ = window.jQuery = JQuery;
require('@nathanvda/cocoon');

import LocalTime from 'local-time';
window.LocalTime = LocalTime;
window.LocalTime.start();

var FroalaEditor = require('../libs/froala/froala.editor');
require('../libs/froala/lists.min.js');
require('../libs/froala/table.min.js');
require('../libs/froala/link.min.js');
window.FroalaEditor = FroalaEditor;

import toastr from 'toastr';
window.toastr = toastr;

import Sortable from 'sortablejs/modular/sortable.core.esm.js';
window.Sortable = Sortable;

const Popper = require('popper.js').default;
import 'bootstrap/dist/js/bootstrap';

import Mustache from 'mustache';
window.Mustache = Mustache;

import Swal from 'sweetalert2';
window.Swal = Swal;

import * as dragula from 'dragula';
window.dragula = dragula;

import * as Raphael from 'raphael';
window.Raphael = Raphael;

import * as Highcharts from 'highcharts';
window.Highcharts = Highcharts;

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
window.Calendar = Calendar;
window.CalendarDayGridPlugin = dayGridPlugin;

require('jquery-ui');
require('bootstrap-switch');
require('jquery-mask-plugin/src/jquery.mask');
require('@dmoz/pickadate/lib/picker');
require('@dmoz/pickadate/lib/picker.date');
require('@dmoz/pickadate/lib/picker.time');
require('datatables.net-bs4');
require('select2');
require('morris.js/morris');
require('jquery-timepicker/jquery.timepicker');

require('../libs/bootstrap-datepicker');
require('../libs/bootstrap-material-datetimepicker');
require('../libs/jquery.datatables.pageLoadMore');

require('../theme/app');
require('../theme/app.init.dark');
require('../theme/waves');
require('../theme/sidebarmenu');

require('../theme/custom.js');

require('../pages/common/init.js');

require('channels');

const images = require.context('../images', true);

window.goFullScreen = () => {
  var element = document.querySelector('body');

  element.requestFullscreen();
};
