$(document).on('turbolinks:load', function(){
  var confirmUserMerge = function (result) {
    if (!result.isConfirmed) return;

    var for_delete_id = $('.check-user-merge.chk-col-amber:checked').attr('value');
    var user_id = $('.check-user-merge.chk-col-primary:checked').attr('value');
    $.ajax({
      type: 'PUT',
      url: '/users/merges/' + user_id,
      data: { for_delete_id }
    });
  };

  $('.check-user-merge').on('change', function(e){
    if ($('.check-user-merge:checked').length >= 2)
      $('.check-user-merge:not(:checked)').attr('disabled', 'disabled')
    else
      $('.check-user-merge').removeAttr('disabled')

    if ($('.check-user-merge:checked').length == 1) {
      $('.check-user-merge:not(:checked)').removeClass('chk-col-primary').addClass('chk-col-amber');
      $('.check-user-merge:checked').addClass('chk-col-primary').removeClass('chk-col-amber');
    } else if ($('.check-user-merge:checked').length != 2)
      $('.check-user-merge').removeClass('chk-col-amber').addClass('chk-col-primary');

    if ($('.check-user-merge:checked').length == 2)
      $('.merge-users-btn').removeClass('visibility-hidden');
    else
      $('.merge-users-btn').addClass('visibility-hidden');
  });

  $('.merge-users-btn').on('click', function(){
    window.swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      html: 'Merge a user is a dangerous operation and the action is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, I'm sure",
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(confirmUserMerge);
  });
});