$(document).on("turbolinks:load", function () {
  $('[name^="leagues_round_types_settings[options]').on("change", function () {
    var formId = $(this).attr("id").replace("leagues_round_types_settings_options_", "");
    if ($(this).is(":checked")) {
      $("#" + formId).removeClass("d-none");
    } else {
      $("#" + formId).addClass("d-none");
    }
  });

  $("#leagues_round_types_settings_start_date").mask("00/00/0000").pickadate();
  $("#leagues_round_types_settings_start_time").bootstrapMaterialDatePicker(
    window.TimePickerDefaultSettings
  );

  $(document).on("change", "#leagues_round_types_settings_round_type", function () {
    if ($(this).val() != "regular") $(this).addClass("text-orange");
    else $(this).removeClass("text-orange");
  });

  if (
    $("body").attr("class").includes("controller-leagues-round_types-settings") ||
    $("body").attr("class").includes("controller-leagues-round_types-handicaps")
  )
    $("#round_type_id").on("change", function (e) {
      var url = $(e.target.form).attr("action").replace(":round_type_id", $(this).val());
      $(e.target.form).attr("action", url).trigger("submit");
    });

  $(".controller-leagues-round_types-round_types #select-round-date").on("click", function (e) {
    e.preventDefault();
    if ($("#round_type_id").val() == "") return;

    var form = $("#round_type_id")[0].form;
    var url = $(form)
      .attr("action")
      .replace("%3Around_type_id", $("#round_type_id").val())
      .replace(":round_type_id", $("#round_type_id").val());
    window.location.href = url;
  });

  if ($("body").hasClass("controller-leagues-round_types-settings")) {
    $("input.switch-input").hide();
    setTimeout(function () {
      $("input.switch-input").each(function () {
        $(this).bootstrapSwitch("state", $(this).attr("checked"));
      });
    }, 300);

    $(
      '[name="leagues_round_types_settings[round_format]"], #leagues_round_types_settings_teams_size'
    )
      .on("change", function () {
        const current = $('[name="leagues_round_types_settings[round_format]"]:checked').val();
        const teamsSize =
          gon.teams_size || parseInt($("#leagues_round_types_settings_teams_size").val());
        if (current === "best_ball" && teamsSize > 2) $(".best-ball").removeClass("d-none");
        else $(".best-ball").addClass("d-none");
      })
      .trigger("change");
  }
});
