(function () {
  const outingLeaderboardConfig = function () {
    const bindRemoveImages = () => {
      $('.remove-image').on('click', function (e) {
        window.stopEvent(e);

        $(this).parent().find('input[type=file]').val('').trigger('change');
        $(this).parent().find('.custom-file-label').text('Choose file');
        $($(this).attr('data-preview-target')).css('visibility', 'hidden');
        $($(this).attr('data-target')).val('true');
      });
    };
    const init = () => {
      $('.custom-file-input').on('change', function (e) {
        const previewTarget = $(this).attr('data-preview');
        if (previewTarget && e.target.files.length > 0) {
          var src = URL.createObjectURL(e.target.files[0]);
          var preview = $(previewTarget)[0];
          preview.src = src;
          $(previewTarget).css('visibility', 'visible');
          $(this)
            .parent()
            .parent()
            .parent()
            .find('.remove-image-input')
            .val(null);
        }
      });
      bindRemoveImages();
    };

    $(document).on('turbolinks:load', () => {
      if (!$('body').attr('class').includes('leaderboards')) return;

      init();
    });
  };

  if (typeof window !== 'undefined') {
    window.outingLeaderboardConfig = outingLeaderboardConfig();
  }

  const outingLeaderboardShowApp = function ({
    pageRotationIntervalSeconds,
    force,
    refreshPageSeconds,
  }) {
    const init = () => {
      $('body .container').removeClass('container');

      window.rotateInterval = setInterval(() => {
        $('.page:not(.d-none)').next().removeClass('d-none');
        $('.page:not(.d-none):first').addClass('d-none');

        if ($('.page:not(.d-none)').length === 0) {
          clearInterval(rotateInterval);
          $('.leaderboards-loader').removeClass('d-none');
          $.get(window.location.href + '&reload=true');
        }
        // $('.page:first').removeClass('d-none');
      }, pageRotationIntervalSeconds * 1000);

      // window.reloadInterval = setInterval(() => {
      //   clearInterval(rotateInterval);
      //   clearInterval(reloadInterval);
      //   $.get(window.location.href + '&reload=true');
      // }, refreshPageSeconds * 1000);
    };

    $(document).on('turbolinks:load', () => {
      if (!$('body').attr('class').includes('leaderboards')) return;

      init();
    });

    if (force) init();
  };

  if (typeof window !== 'undefined') {
    window.outingLeaderboardShowApp = outingLeaderboardShowApp;
  }
})();
