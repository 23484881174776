(function () {
  const playerPaymentsApp = function () {
    const mountCard = () => {
      var elements = stripe.elements();
      var card = elements.create('card');

      card.addEventListener('change', function(event) {
        $('.pay-button').attr('disabled', null);
        if (event.error) {
          $('#card-errors').text(event.error.message);
        } else {
          $('#card-errors').text('');
        }
      });

      card.mount('#card-element');
      return card;
    };

    const mountCardData = (card) => {
      return {
        payment_method: {
          card: card,
          billing_details: {
            address: {
              postal_code: gon.postal_code,
            },
          },
        }
      };
    }

    const pay = (paymentData) => {
      const url = `/players/leagues/${gon.league_id}/payments`;
      const method = 'POST';
      const data = {
        setup_intent_id: paymentData.setupIntent.id,
        new_payment_method_id: paymentData.setupIntent.payment_method
      }

      $.ajax({ url, method, data });
    }

    const confirmCardSetup = (card) => {
      stripe.confirmCardSetup(
        gon.setup_intent_id, mountCardData(card)
      ).then(paymentData => {
        if (paymentData.setupIntent)
          return pay(paymentData)

        $('.messages').text(paymentData.error.message);
        $('.pay-button').attr('disabled', null);
      });
    }

    const submitCard = (card) => {
      stripe.createToken(card).then(function(result) {
        if (result.error) {
          $('.messages').text(result.error.message);
          $('.pay-button').attr('disabled', null);
        } else {
          confirmCardSetup(card)
        }
      });
    }

    const initStripeCardInput = () => {
      const card = mountCard();
      $('.pay-button').on('click', function(e) {
        $(this).prop('disabled', true);
        if ($('#payment_payment_method_id').val() !== '') {
          return $($('#payment_payment_method_id')[0].form).trigger('submit');
        }
        e.preventDefault();

        submitCard(card);
        return false;
      });
    };

    const initSelectPaymentMethod = () => {
      $('#payment_payment_method_id').on('change', function() {
        if ($('#payment_payment_method_id').val() !== '') {
          return $('#stripe-form, .messages').addClass('d-none');
        };

        $('#stripe-form, .messages').removeClass('d-none');
      }).trigger('change');
    };

    $(document).on('turbolinks:load', () => {
      if ($('body').hasClass('controller-players-payments')){
        initStripeCardInput();
        initSelectPaymentMethod();
      }
    });
  };

  if (typeof window !== "undefined") {
    window.playerPaymentsApp = playerPaymentsApp();
  }
})();
