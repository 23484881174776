(function () {
  const bodyClass = 'controller-leagues-players-invites';
  const playerCheckbox = 'tr[data-id] .form-check input[type=checkbox]';
  const inviteBtn = '.invite-button';
  const inviteAllBtn = '#invite_user.select-all';

  const InviteLeaguePlayersListApp = function () {
    let playerIds = [];

    const toggleInviteBtn = () => {
      const visibility = playerIds.length > 0 ? 'visible' : 'hidden';
      $(inviteBtn).css('visibility', visibility);
    }

    const toggleInviteAll = () => {
      const checked = $(playerCheckbox).length !== playerIds.length;
      $(playerCheckbox).prop('checked', checked).trigger('change');
    };

    const togglePlayerCheckbox = function() {
      const id = $(this).val();
      if ($(this).prop('checked') && playerIds.indexOf(id) === -1) {
        playerIds.push(id)
      } else if (!$(this).prop('checked')) {
        delete playerIds[playerIds.indexOf(id)]
        playerIds = playerIds.filter(String);
      }

      toggleInviteBtn();
    };

    const init = function() {
      if (!$('body').hasClass(bodyClass)) return;

      $(playerCheckbox).on('change', togglePlayerCheckbox);
      $(inviteAllBtn).on('change', toggleInviteAll);
      toggleInviteBtn();

      $('.check-players').on('click', function(e) {
        window.stopEvent(e);
        $('[data-player] input').trigger('click');
      });

      $('.check-subs').on('click', function(e) {
        window.stopEvent(e);
        $('[data-sub] input').trigger('click');
      });
    }

    $(document).on('turbolinks:load', init);

    return { playerIds };
  };

  if (typeof window !== "undefined") {
    window.InviteLeaguePlayersListApp = InviteLeaguePlayersListApp();
  }

  return InviteLeaguePlayersListApp;
})();
