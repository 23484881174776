(function () {
  const setupWizardWarn = '.setup-wizard-warn';
  const createMatchesWarn = '.create-matches-warn';
  const deleteMatchesWarn = '.delete-matches-warn';
  const createMatchesActions = '.create-matches-action';
  const dropdown = '.league-actions-btn';
  const secondLevelDropdowns = '.dropdown-menu.second-level';

  const leagueActionsApp = function () {
    const bindSetupWizardWarn = () => {
      $(setupWizardWarn).on('click', function (e) {
        e.preventDefault();

        var url = $(this).attr('href');
        window.swalConfirm
          .fire({
            title: 'Warning',
            text: 'If you have pairings, custom round settings, \
                 or scores this setup wizard may cause adverse changes \
                 to your league.  If you are simply looking to change \
                 settings for a round please use the round settings \
                 option on the league actions menu.',
            cancelButtonText: 'Return to Dashboard',
            confirmButtonText: 'Continue',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-link mr-2',
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.href = url;
            } else {
              window.location.href = `/dashboards/leagues/${gon.league_id}`;
            }
          });
      });
    };

    const createMatches = (isNormalRound) => {
      if (!window.teeOffConfiguration || window.teeOffConfiguration === '')
        confirmAndCreateMatch(isNormalRound);
      else
        window.swalConfirm
          .fire({
            title: 'Are you sure?',
            html: `Do you want to use the current tee off configuration? <br> ${window.teeOffConfiguration}`,
            cancelButtonText: 'No, change configuration',
            confirmButtonText: 'Yes, create',
          })
          .then((result) => {
            if (!result.isConfirmed)
              return (window.location.href = `/league_settings/${gon.league_id}/pairings/pairings/edit?from_league_actions=true`);

            confirmAndCreateMatch(isNormalRound);
          });
    };

    const confirmAndCreateMatch = (isNormalRound) => {
      window.swalConfirm
        .fire({
          title: 'Are you sure?',
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes, create',
        })
        .then((result) => {
          if (!result.isConfirmed) return;

          dispatchCreateMatchesRequest(isNormalRound);
        });
    };

    const dispatchCreateMatchesRequest = (isNormalRound) => {
      $('.loader').removeClass('d-none');
      $.ajax({
        type: 'POST',
        data: { cross_divisions: !isNormalRound },
        url: `/leagues/${gon.league_id}/round_robin`,
        success: function () {
          window.location.reload();
        },
      });
    };

    const askRoundRobinsType = () => {
      window.swalConfirm.fire({
        title: 'Question',
        icon: 'question',
        html: Mustache.render($('#mustache_delete_matches_alert').html()),
        showCancelButton: false,
        showConfirmButton: false,
      });
    };

    const bindCreateMatches = () => {
      $(createMatchesWarn).on('click', function (e) {
        e.preventDefault();

        if (window.leagueHasMatches) {
          window.swalWithBootstrapButtons.fire({
            title: 'Warning',
            text: 'This league already has created pairings. You need to delete them before create again.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        } else {
          if (window.leagueHasDivisions) askRoundRobinsType();
          else createMatches(true);
        }
      });
    };

    const bindDeleteMatches = () => {
      $(deleteMatchesWarn).on('click', function (e) {
        e.preventDefault();

        if (window.leagueHasScorings) {
          return window.swal.fire({
            icon: 'error',
            title: 'You cannot do it',
            text: 'Pairings cannot be removed, scores have been entered',
          });
        }

        window.swalConfirm
          .fire({
            title: 'Are you sure?',
            text: 'If you have custom round settings or scores added, \
                 this option may cause adverse changes to your league. \
                 If you continue ALL pairings and tee groups will be removed.',
            cancelButtonText: 'Return to dashboard',
            confirmButtonText: 'Continue',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-link mr-2',
            },
          })
          .then((result) => {
            if (!result.isConfirmed)
              return (window.location.href = `/dashboards/leagues/${gon.league_id}`);

            $('.loader').removeClass('d-none');
            $.ajax({
              type: 'DELETE',
              url: `/leagues/${gon.league_id}/round_robin`,
              success: function () {
                window.location.reload();
              },
            });
          });
      });
    };

    const bindCreateMatchesActions = () => {
      $(document).on('click', createMatchesActions, function (e) {
        e.preventDefault();
        if ($(this).attr('id') === 'to-dash') {
          return (window.location.href = `/dashboards/leagues/${gon.league_id}`);
        }

        createMatches($(this).attr('id') !== 'cross-divisions');
      });
    };

    const bindOnHideDropDown = () => {
      $(dropdown)
        .parent()
        .on('hide.bs.dropdown', function () {
          $(this)
            .find(secondLevelDropdowns)
            .removeClass('d-table')
            .addClass('d-none');
        });
    };

    const init = () => {
      bindSetupWizardWarn();
      bindCreateMatches();
      bindCreateMatchesActions();
      bindDeleteMatches();
      bindOnHideDropDown();
    };

    $(document).on('turbolinks:load', init);
  };

  if (typeof window !== 'undefined') {
    window.leagueActionsApp = leagueActionsApp();
  }

  return leagueActionsApp;
})();
