(function () {
  const input = 'input#import_csv';
  const loader = '.generic-loader';
  const bodyClasses = ['controller-league_settings-players-players', 'controller-leagues-players-subs'];
  const modal = '.modal-box .modal';
  const cancelUpload = '.cancel-upload';
  const confirmImport = '.confirm-import'

  const uploadVerificationApp = function () {
    const bindInputChange = (event) => {
      if(event.target.files.length > 0){
        $(loader).removeClass('d-none');
        $(event.target.form).find('[type=submit]').trigger('click');
      }
    }

    const init = () => {
      $(document).on('turbolinks:load', () => {
        for (className of bodyClasses) {
          if ($('body').hasClass(className))
            return $(input).on('change', bindInputChange);
        }
      });
    }

    const confirmUpload = () => {
      $(cancelUpload).on('click', function(){
        $(input).val('').trigger('change');
        $(modal).modal('hide');
      });

      $(confirmImport).on('click', function(){
        $($(input)[0].form).attr('action', window.uploadPlayersUrl)
                           .removeAttr('data-remote')
                           .find('[type=submit]').trigger('click');
        $(loader).removeClass('d-none');
      });
    }

    return { init, confirmUpload };
  };

  if (typeof window !== "undefined") {
    window.uploadVerificationApp = uploadVerificationApp();
    window.uploadVerificationApp.init();
  }

})();
