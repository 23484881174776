(function () {
  const LeagueShareReportsApp = function () {
    $(document).on('click', '.share-report', function(e) {
      window.stopEvent(e);

      window.swalWithBootstrapButtons.fire({
        title: 'Share report',
        html: Mustache.render($('#share_report_options').html()),
        icon: 'info',
        showCancelButton: false,
        showConfirmButton: false,
      });
    });
  };

  if (typeof window !== 'undefined') {
    window.LeagueShareReportsApp = LeagueShareReportsApp;
  }

  return LeagueShareReportsApp();
})();
