$(document).on('turbolinks:load', function(){
  const bindCreateNewLeague = function(e) {
    if (!window.activeLeagues || window.activeLeagues.length === 0) return;

    window.stopEvent(e);

    window.swalConfirm.fire({
      html: Mustache.render($('#mustache_create_league_warn').html()),
      showConfirmButton: false,
      showCancelButton: false,
      width: '600px'
    });
  }

  $('aside.left-sidebar .create-new-league-link').on('click', bindCreateNewLeague);

  $(document).on('click', '.create-link-go-to-league', function() {
    window.location.href = `/dashboards/leagues/${$(this).data('league-id')}`;
  });

  $(document).on('click', '.create-link-go-to-new-league', function() {
    $('.dataTables_wrapper .dataTable').DataTable().destroy(true);
    $.ajax({
      url: $('aside.left-sidebar .create-new-league-link').attr('href'),
      method: 'POST',
      dataType: 'script'
    });
  });
});