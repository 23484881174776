(function () {
  const LeaguePlayerCategoriesTeesApp = function (teesOptions, filledRoundIds) {
    const showOrHideAlert = (roundId, form) => {
      const formAlert = $(form).find(".alert");
      const formSubmit = $(form).find(".btn-primary");

      console.log(filledRoundIds, roundId);
      if (filledRoundIds.includes(roundId)) {
        const text = `You can't change the tees because your league already has scores for ${$(
          `#round_id option[value="${roundId}"]`
        ).text()}`;
        formAlert.removeClass("d-none").text(text);
        formSubmit.addClass("d-none");
        return;
      }

      formSubmit.removeClass("d-none");
      formAlert.addClass("d-none");
    };

    const teesToOptions = (tees) => {
      let options = "";
      for (let i = 0; i < tees.length; i++)
        options += $("<option>")
          .attr("value", tees[i].id)
          .text(tees[i].full_name)[0].outerHTML;
      return options;
    };

    const changeTeeOptions = (e) => {
      const roundId = $("#round_id").val();

      showOrHideAlert(roundId, e.target.form);

      const tees = teesOptions[roundId];
      $("#tee_id").html(teesToOptions(tees)).select2();
    };

    $("#round_id").on("change", changeTeeOptions).trigger("change");
  };

  if (typeof window !== "undefined") {
    window.LeaguePlayerCategoriesTeesApp = LeaguePlayerCategoriesTeesApp;
  }

  return LeaguePlayerCategoriesTeesApp;
})();
