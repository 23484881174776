(function () {
  const element = 'handicap-chart';

  const reportsPlayerDashboardCurrentHandicap = function () {
    const data = gon?.handicap?.report;
    if (!data) return;

    Highcharts.chart(element, {
      chart: {
        backgroundColor: 'transparent',
        marginRight: screen.width > 768 ? 70 : 0,
      },
      title: {
        text: '',
      },
      xAxis: [
        {
          categories: data.rounds,
          lineWidth: 0,
        },
      ],
      yAxis: [
        {
          gridLineWidth: 0,
          allowDecimals: false,
          labels: {
            style: { color: Highcharts.getOptions().colors[1] },
          },
          title: {
            text: '',
            style: { color: Highcharts.getOptions().colors[1] },
          },
        },
        {
          allowDecimals: false,
          title: {
            text: '',
            style: { color: Highcharts.getOptions().colors[0] },
          },
          labels: {
            style: { color: Highcharts.getOptions().colors[0] },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: { itemStyle: { color: Highcharts.getOptions().colors[1] } },
      series: [
        {
          name: 'User handicap',
          type: 'column',
          data: arrayToFloat(data.handicaps),
        },
        {
          name: 'Round average',
          type: 'spline',
          data: arrayToFloat(data.averages),
        },
      ],
    });
  };

  if (typeof window !== 'undefined') {
    window.reportsPlayerDashboardCurrentHandicap =
      reportsPlayerDashboardCurrentHandicap;
  }

  return reportsPlayerDashboardCurrentHandicap;
})();
