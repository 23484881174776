(function () {
  const mobileScoringInputSelector = 'input.mobile-scoring-input';
  const loaderSelector = '.mobile-scoring-loader';

  const updateMobileScoringOnApi = function(_event, mobileScoring) {
    if (gon.demo_user)
      return window.leagueSamplesApp.showAlert();

    $(loaderSelector).removeClass('d-none');
    var url = `/league_settings/${gon.league_id}/mobile_scorings`;

    changeOffLabel(mobileScoring);

    $.ajax({
      type: 'PUT',
      url: url,
      data: { league_settings_mobile_scoring: { mobile_scoring: mobileScoring } },
      success: function(){
        $(loaderSelector).addClass('d-none');
      }
    });
  }

  const changeOffLabel = (mobileScoring) => {
    if (!mobileScoring && mobileScoring !== false) mobileScoring = window.mobileScoring;
    var label = $(mobileScoringInputSelector).parent().find('.bootstrap-switch-label');
    var offText = mobileScoring ? 'OFF' : 'ON';
    label.text(offText);
  };

  const bindBootstrapSwitch = function(){
    $(this).bootstrapSwitch();
    $(this).on('switchChange.bootstrapSwitch', updateMobileScoringOnApi);
    $(this).bootstrapSwitch('state', window.mobileScoring);
    changeOffLabel();
  }

  const leagueActionsMenu = function () {
    const bindMobileScoring = function() {
      $(mobileScoringInputSelector).hide();
      $(mobileScoringInputSelector).each(bindBootstrapSwitch);
    }

    $(document).on('turbolinks:load', bindMobileScoring);
  };

  if (typeof window !== "undefined") {
    window.leagueActionsMenu = leagueActionsMenu;
  }

  return leagueActionsMenu();
})();
