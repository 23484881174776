(function () {
  const LeagueSubmitScoresApp = function () {
    const collectScoreInformation = (string) =>
      string.split("][").map((str) => str.split("[")[1] || str.split("[")[0].replace("]", ""));

    const scoreData = (block) => {
      const data = { match_id: gon.match_id };
      const { playerId, teamId } = block;
      delete block.teamId;
      delete block.playerId;
      const scores = {};
      scores[teamId] = {};
      scores[teamId][playerId] = block;

      return { ...data, scores };
    };

    const saveScores = (blocks) => {
      const promises = Object.values(blocks).map((block) => {
        return $.ajax({
          type: "POST",
          url: `/leagues/${gon.league_id}/scores`,
          data: scoreData(block),
        });
      });

      $(".generic-loader").removeClass("d-none");
      Promise.all(promises).then(
        () =>
          (window.location.href = `/leagues/${gon.league_id}/scores?round_type_id=${gon.round_type_id}`)
      );
    };

    const submitScores = (form) => {
      const formData = $(form).serializeArray();
      const submitBlocks = {};
      for (let i = 0; i < formData.length; i++) {
        const data = formData[i];
        if (!data.name.includes("scores[")) continue;

        const [teamId, playerId, holeId] = collectScoreInformation(data.name);

        if (!submitBlocks[playerId]) submitBlocks[playerId] = { teamId, playerId };
        submitBlocks[playerId][holeId] = data.value;
      }
      saveScores(submitBlocks);
    };

    const init = () => {
      if (
        !$("body").hasClass("controller-scorings-scores action-index") ||
        $('[name*="scores["]').length === 0
      )
        return;

      $($('[name*="scores["]')[0].form).on("submit", function (e) {
        window.stopEvent(e);
        submitScores(this);
      });
    };

    $(document).on("turbolinks:load", init);
  };

  if (typeof window !== "undefined") {
    window.LeagueSubmitScoresApp = LeagueSubmitScoresApp();
  }

  return LeagueSubmitScoresApp;
})();
