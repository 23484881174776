window.bindStandingsTable = function() {
  if ($('#teams-dashboard').length === 0) return false;

  $.fn.DataTable.ext.pager.numbers_length = 5;
  var url = '/leagues/' + gon.league_id + '/teams';

  if (gon.current_season_id) {
    url+= '?season_id=' + gon.current_season_id;
    gon.season_id = gon.current_season_id
  }

  const pageLength = window.location.href.includes('player_dashboard') ? 5 : 100;

  window.standingsTable = $('#teams-dashboard').DataTable({
    info: false,
    pageLength,
    lengthMenu: [5, 10, 25, 50, 100],
    ajax: { url },
    createdRow: function( row, data ) {
      var team_player_ids = data.players.map((p) => p.id);
      if (team_player_ids.includes(gon.current_user_id)){
        $(row).find('td').addClass('bg-black');
      }
    },
    ext: {
      pager: {
        numbers_length: 2
      },
    },
    columns: [
      {
        data: 'position',
        className: 'text-white'
      },
      {
        data: 'name',
        className: 'text-primary'
      },
      {
        data: 'points',
        className: 'text-right'
      },
    ]
  });
}

$(document).on('turbolinks:load', window.bindStandingsTable);
$(document).on('turbolinks:load', function(){
  $('.divisions-dd a.dropdown-item').on('click', function(e){
    e.preventDefault();
    gon.division_id = $(this).data('division-id');
    window.standingsTable.ajax.url(
      `/leagues/${gon.league_id}/teams?division_id=${gon.division_id}${gon.season_id ? `&season_id=${gon.season_id}` : ''}`
    )
    $('#divisions-dd').text($(this).text())
    window.standingsTable.draw();
  });

  $('.round-types-dd a.dropdown-item').on('click', function(e){
    e.preventDefault();
    gon.round_type_id = $(this).data('round-type-id');
    window.standingsTable.ajax.url(
      `/leagues/${gon.league_id}/teams?division_id=${gon.division_id}${gon.season_id ? `&season_id=${gon.season_id}` : ''}&round_type_id=${gon.round_type_id}`
    )
    $('#round-types-dd').text($(this).text())
    window.standingsTable.draw();
  });

  $('.seasons-dd a.dropdown-item').on('click', function(e){
    e.preventDefault();
    gon.season_id = $(this).data('season-id');
    var url = '/leagues/' + gon.league_id + '/teams?division_id=' + gon.division_id;
    url += '&season_id=' + gon.season_id;

    window.standingsTable.ajax.url(
      `/leagues/${gon.league_id}/teams?division_id=${gon.division_id}&season_id=${gon.season_id}`
    );
    $('#seasons-dd').text($(this).text())
    $('#round-types-dd').text('Pick a round')
    window.standingsTable.draw();
  });
});