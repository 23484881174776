(function () {
  const payCheckSelector = '.pay-check';
  const totalSelector = '.total';
  const modalPlayersField = '.modal input[name="payment[players]"]';
  const selectAllSelector = '#select_all';
  const wrapperSelector = '.league-admin-payments';

  const updateTotal = (e) => {
    let total = parseFloat($('.pay-button').attr('data-value'));
    // $(`${payCheckSelector}:checked`).each((_, item) => {
    //   var price = $(item).closest('tr').data('price');
    //   total += parseFloat(price);
    // });
    $(totalSelector).text(total.toFixed(2));
  };

  const updatePlayersField = () => {
    const players = [];
    $(`${payCheckSelector}:checked`).each((_, item) => {
      var id = $(item).closest('tr').data('id');
      players.push(id);
      $(modalPlayersField).val(JSON.stringify(players));
    });
  };

  const bindPayCheck = () => {
    $(payCheckSelector).on('change', updateTotal);
    $(payCheckSelector).on('change', updatePlayersField);
  };

  const bindSelectAll = () => {
    $(selectAllSelector).on('click', (e) => {
      const value = $(e.target).prop('checked');
      $('input[type=checkbox]:enabled').prop('checked', value);
      updateTotal();
      updatePlayersField();
    });
  };

  const leaguePaymentsLeagueAdmin = function () {
    const init = () => {
      bindPayCheck();
      bindSelectAll();
      $('input[type=checkbox]:enabled').prop('checked', true);
      updateTotal();
      updatePlayersField();
    };

    $(document).on('turbolinks:load', () => {
      if (
        $('body').hasClass('controller-payments-payments') &&
        $(wrapperSelector).length > 0
      )
        init();
    });

    return { init, updatePlayersField };
  };

  if (typeof window !== 'undefined') {
    window.leaguePaymentsLeagueAdmin = leaguePaymentsLeagueAdmin();
  }
})();
