$(document).on("hidden.bs.modal", "#manually-form-modal", function () {
  $("#manually-form-modal-container").html(resetedForm);
});

window.bindingModalEvents = function () {
  var select2Data = [];
  var url = "/users/search?by_home_course=true&league_id=" + (gon.league_id || leagueId);
  if (window.subsScreen) url += "&subs=true";
  $(".modal .player-container select.select2")
    .select2({
      ajax: {
        url: url,
        dataType: "json",
        delay: 400,
        processResults: function (data) {
          select2Data = data.data;
          return {
            results: data.data.map(function (elem) {
              var text;
              if (elem.full_name && elem.full_name.trim() != "") text = elem.full_name;
              else text = elem.email && elem.email.trim() != "" ? elem.email : elem.phone;

              if (elem.select2_description) text = elem.select2_description;
              return { id: elem.id, text: text };
            }),
          };
        },
      },
    })
    .on("change", function (event) {
      var player = select2Data.find((elem) => elem.id === event.target.value);
      const container = $(this).parent().parent().parent();
      container
        .find('input[name*="first_name"]')
        .attr("readonly", "readonly")
        .val(player.first_name);
      container.find('input[name*="last_name"]').attr("readonly", "readonly").val(player.last_name);
      container.find('input[name*="phone"]').attr("readonly", "readonly").val(player.phone);
      container.find('input[name*="email"]').attr("readonly", "readonly").val(player.email);
      container
        .find('select[name*="gender"]')
        .val(player.gender)
        .find("option")
        .attr("disabled", "disabled");
      // .trigger('change');
      container.find(".row:not(.search-player-box)").each(function () {
        $(this).css("opacity", 0.5);
      });
    });

  // $('.gender-select2').select2();

  $(".phone-mask").mask("+1 (999) 999-9999");

  $("#manually-form-modal-container form").on("submit", function (e) {
    $(this).find("[type=submit]").addClass("invisible");
  });
};

$(document).on("shown.bs.modal", "#manually-form-modal", function (e) {
  window.bindingModalEvents();
  e.preventDefault();
  e.stopImmediatePropagation();
});

$(document).on("click", ".add-manually-single-player-warning", function (e) {
  window.stopEvent(e);
  var link = $("<a>")
    .attr("href", `/leagues/${gon.league_id}/replaces/new`)
    .attr("data-turbolinks", "false")
    .text("Replace Player / Add team");
  window.swalWithBootstrapButtons.fire({
    title: "Oops",
    html: `Changes are not allowed after pairings have been created. \
           Please use the ${link[0].outerHTML} button at the top of the player or team page.`,
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Ok",
  });
});
