(function () {
  const createPaymentMethodUrl = () =>
    gon.league_id ?
      `/leagues/${gon.league_id}/payments/payment_methods` :
      `/payments/courses/${gon.course_id}/payment_methods`;
  const cardErrorsSelector = '#card-errors';
  const submitStripeSelector = '#submit-stripe';
  const cardElementSelector = '#card-element';
  const paymentMethodModal = '.stripe-payment-method-modal';
  const paymentModal = '.stripe-modal';
  const cancelLinkSelector = '.stripe-payment-method-modal .cancel-link';

  const mountCard = () => {
    var elements = stripe.elements();
    var card = elements.create('card');

    card.addEventListener('change', function(event) {
      $(submitStripeSelector).attr('disabled', null);
      if (event.error) {
        $(cardErrorsSelector).text(event.error.message);
      } else {
        $(cardErrorsSelector).text('');
      }
    });

    card.mount(cardElementSelector);
    return card;
  };

  const postPaymentMethod = (paymentData) => {
    const url = createPaymentMethodUrl();
    const method = 'POST';
    const data = {
      setup_intent_id: paymentData.setupIntent.id,
      payment_method_id: paymentData.setupIntent.payment_method
    }

    $.ajax({ url, method, data });
  }

  const createPaymentMethod = (paymentData) => {
    if (paymentData.setupIntent)
      return postPaymentMethod(paymentData)

    $(cardErrorsSelector).text(paymentData.error.message);
    $(submitStripeSelector).attr('disabled', false);
  };

  const submitPayment = (card) => {
    stripe.confirmCardSetup(gon.setup_intent_id, {
      payment_method: {
        card: card,
        billing_details: {
          address: {
            postal_code: gon.postal_code,
          },
        },
      },
    }).then(data => {
      createPaymentMethod(data);
    });
  };

  const createStripeToken = (card) => {
    stripe.createToken(card).then(function(result) {
      if (result.error) {
        $(cardErrorsSelector).text(result.error.message);
      } else {
        submitPayment(card);
      }
    });
  };

  const bindPaymentButton = (card) => {
    $(submitStripeSelector).on('click', function(e) {
      $(this).prop('disabled', true);
      e.preventDefault();

      createStripeToken(card);
      return false;
    });
  }

  const bindCancelButton = () => {
    $(cancelLinkSelector).on('click', function(){
      $(paymentMethodModal).modal('hide');
      $(paymentModal).modal('show');
    });
  }

  const leaguePaymentsStripe = function () {
    const init = () => {
      const card = mountCard();
      bindPaymentButton(card);
      bindCancelButton();
    };

    return init();
  };

  if (typeof window !== "undefined") {
    window.leaguePaymentsStripe = leaguePaymentsStripe;
  }
})();
