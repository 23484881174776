var loadStandingMatch = function (leagueId, matchId, scope) {
  $('.standing-loader').removeClass('d-none');
  var url = `/leagues/${leagueId}/standings/${matchId}.js`;
  var publicUrl = `/public/leagues/${leagueId}/reports/public_results_details/${matchId}.js`

  if ($('body').attr('class').includes('public'))
    url = publicUrl;

  $.ajax({
    type: 'GET',
    url: url,
    success: function() {
      openMatch(scope);
    },
    error: function(error){
      console.log(error);
    }
  });
};

var openMatch = function (scope) {
  scope.find('i').toggleClass('mdi-chevron-down').toggleClass('mdi-chevron-up');
  scope.next('.results').toggleClass('d-none');
  $('.standing-loader').addClass('d-none');
}

$(document).on('turbolinks:load', function(){
  var bodyClass = '.controller-scorings-standings.action-index, '
  bodyClass += '.controller-reports-public_results_details.action-index'

  $(`${bodyClass}`).find('.filters select').on('change', function(e){
    $(e.target.form).trigger('submit');
  });

  $(document).on('click', '.toggle-standing', function(e){
    e.preventDefault();
    var matchId = $(this).attr('data-match-id');
    var leagueId = $(this).attr('data-league-id');
    if ($('#standing-' + matchId).find('div').length === 0)
      loadStandingMatch(leagueId, matchId, $(this))
    else
      openMatch($(this))
  });

  $(document).on('click', '.toggle-calculation', function(){
    $(this).parent().find('.table-responsive').toggleClass('d-none');
    $(this).find('i').toggleClass('mdi-chevron-down').toggleClass('mdi-chevron-up');
  });

  $('.controller-scorings-standings #league_id').on('change', function() {
    window.location.href = `/leagues/${$(this).val()}/standings`;
  });
});
