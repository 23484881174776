(function () {
  const table = "table#admin-leagues-dashboard";

  const dashboardAdminLeaguesDatatable = function () {
    const bindDeleteLeagueLink = () => {
      $(document).on("click", ".delete-league-link", (e) => {
        window.stopEvent(e);
        let that = e.target;
        if ($(that).hasClass("mdi")) that = $(that).parent();
        const leagueName = $(that).parent().parent().find("td").first().find("a").first().text();

        window.swalConfirm
          .fire({
            title: "Are you sure?",
            text: leagueName,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (!result.isConfirmed) return;

            const authenticity_token = $("meta[name=csrf-token]").attr("content");
            $.ajax({
              method: "delete",
              url: $(that).attr("href"),
              data: { authenticity_token },
            });
          });
      });
    };

    const init = () => {
      const url = `/leagues?order_by=created_at${
        gon.white_label_app ? `&white_label_app=${gon.white_label_app}` : ""
      }`;
      window.adminLeaguesDashboard = $(table).DataTable({
        ajax: { url },
        lengthMenu: [10, 25, 50, 100, 500, 1000],
        columns: [
          {
            data: (data) => {
              const url = `/dashboards/leagues/${data.id}`;
              return $("<a>").attr("href", url).attr("data-turbolinks", "false").text(data.name)[0]
                .outerHTML;
            },
          },
          {
            data: "league_holes",
            className: "text-right",
          },
          {
            data: "created_at",
            className: "text-right",
          },
          {
            data: (data) => {
              return data?.home_course?.name || "";
            },
            className: "text-right",
          },
          {
            data: (data) => {
              return data?.home_course?.state_name || "";
            },
            className: "text-right",
          },
          {
            data: "admin_names",
            className: "text-right",
          },
          {
            data: "players_count",
            className: "text-right",
          },
          {
            data: "subs_count",
            className: "text-right",
          },
          {
            data: (data) => {
              return (
                $("<a>")
                  .attr("href", `/leagues/${data.id}`)
                  .attr("data-remote", "true")
                  .text("Admins info")[0].outerHTML +
                $("<a>")
                  .attr("href", `/leagues/${data.id}`)
                  .addClass("ml-2 text-danger delete-league-link")
                  .text("Delete")[0].outerHTML
              );
            },
            className: "text-right",
          },
        ],
      });
    };

    $(document).on("turbolinks:load", () => {
      const hasBodyClass =
        $("body").hasClass("controller-dashboards-base") ||
        $("body").hasClass("controller-dashboards-admin");
      if (hasBodyClass) bindDeleteLeagueLink();
      const hasTable = $(table).length > 0;
      if (!hasBodyClass || !hasTable) return;

      init();
    });
  };

  if (typeof window !== "undefined") {
    window.dashboardAdminLeaguesDatatable = dashboardAdminLeaguesDatatable();
  }

  return dashboardAdminLeaguesDatatable;
})();
