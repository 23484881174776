require('./mobile_scoring.js');

(function () {
  const webAppInitApp = function () {
    const init = () => {};

    $(document).on('turbolinks:load', function () {
      if (
        !$('body')
          .attr('class')
          .includes('controller-leagues-round_types-mobile_scorings')
      )
        return;

      init();
    });
  };

  if (typeof window !== 'undefined') {
    window.webAppInitApp = webAppInitApp();
  }

  return webAppInitApp;
})();
