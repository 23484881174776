var observeTimeinput = function () {
  $(".time-inputs select").on("change", function () {
    if ($(this).hasClass("prevent-open-next")) return;

    var arr = $(this).attr("name").split("[");
    var inputName = "." + arr[arr.length - 1].replaceAll("]", "");
    var timeInputClasses = [".hour", ".minute", ".meridiem"];
    if (!inputName.includes("meridiem"))
      $(this)
        .parent()
        .find(timeInputClasses[timeInputClasses.indexOf(inputName) + 1])
        .select2("open");
    else $(this).closest("tr").next().find("select.hour").select2("open");
  });
};

const bindSecondLevelDropdowns = () => {
  $("li.dropdown-item.dropdown").on("click", function (e) {
    if ($(e.target).closest(".dropdown-item").parent().parent().hasClass("btn-group")) {
      $(".dropdown-menu.second-level").addClass("d-none").removeClass("d-table");
    }
    if (!$(e.target).hasClass("second-level-link")) {
      e.preventDefault();
      e.stopPropagation();
      $(".dropdown-menu.second-level").removeClass("border-1");
      $(this).find(".dropdown-menu").first().toggleClass("d-none").toggleClass("d-table");
      $(".dropdown-menu.second-level:not(.d-none)").last().addClass("border-1");
    }
  });
};

$(document).on("turbolinks:load", function () {
  $(".select2").select2();
  $(".page-link").attr("data-turbolinks", "false");
  observeTimeinput();
  bindSecondLevelDropdowns();
  $(".generic-date-input").mask("00/00/0000").pickadate();

  $(".submit-on-change input, .submit-on-change select, input.submit-on-change").on(
    "change",
    function (e) {
      if ($(this).attr("data-show-loader")) $(".generic-loader").removeClass("d-none");
      $(e.target.form).trigger("submit");
    }
  );
  $("input.submit-on-change[type=hidden]").on("change", function (e) {
    $(e.target.form).trigger("submit");
  });
});

window.bindDatatableTooltips = function () {
  $(this).find('[data-toggle="tooltip"]').tooltip({ boundary: "window", container: "body" });
};

window.swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
    cancelButton: "btn btn-secondary mr-2",
  },
  buttonsStyling: false,
});

window.swal = window.swalWithBootstrapButtons;

window.swalConfirm = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
    cancelButton: "btn btn-secondary mr-2",
  },
  buttonsStyling: false,
  title: "Are you sure?",
  icon: "warning",
  showCancelButton: true,
  showConfirmButton: true,
  cancelButtonText: "No",
  confirmButtonText: "Yes, lets go!",
  reverseButtons: true,
});

Highcharts.setOptions({
  colors: [
    window.primaryColor,
    "#dbdcdd",
    "#1e88e5",
    "#6610f2",
    "#e83e8c",
    "#fb8c00",
    "#ffb22b",
    "#7460ee",
    "#fc4b6c",
    window.primaryColor,
    "#aee0d1",
    "#26c6da",
    "#2e6e59",
    "#323743",
  ],
});

window.stopEvent = (e) => {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
};

$(document).on("click", ".copy-to-clipboard", function (e) {
  window.stopEvent(e);

  const url = $(this).attr("href");
  navigator.clipboard.writeText($("<input>").val(url).val());
});

$(document).on("click", ".show-loader-link", function (e) {
  $(".generic-loader").removeClass("d-none");
});

require("./unfinishedFeatures.js");
require("./ajaxSelect2.js");
require("./samples.js");
require("./confirm.js");
require("./sidebar.js");
require("./leaderboards.js");
