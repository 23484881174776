window.bindContributionsEdit = function(courseId, postPath) {
  var findContribution = function(leagueId) {
    return gon.contributions.find(item => item.league_id === leagueId);
  };

  var findContributionAmount = function(data) {
    var payment = findContribution(data.id);
    return payment ? payment.amount : 0;
  };

  var findContributionPayForAll = function(data) {
    var payment = findContribution(data.id);
    return payment ? payment.pay_for_all : false;
  };

  var updateContributionInput = function(leagueId) {
    var target = $('#modal .contribution-input[data-league-id='+ leagueId +']')
    if(findContribution(leagueId).pay_for_all)
      target.attr('disabled', 'disabled').val('');
    else
      target.removeAttr('disabled').val('0');
  }

  $('#league-contributions').DataTable({
    ajax: {
      url: '/leagues?course_id=' + courseId,
    },
    columns: [
      {
        data: 'name',
        className: 'vertical-align-middle'
      },
      {
        data: function(data) {
          return Mustache.render($('#mustache_contribution_pay_for_all').html(), {
            checked: findContributionPayForAll(data),
            leagueId: data.id,
            disabled: data.due_value === '$0' || findContribution(data.id)?.pay_for_all
          });
        },
      },
      {
        data: function(data) {
          return Mustache.render($('#mustache_contribution_input').html(), {
            value: findContributionAmount(data),
            leagueId: data.id,
            disabled: data.due_value === '$0'
          });
        },
      },
    ],
  }).on('draw.dt', function(){
    $('#modal .paginate_button:not(.active) a').addClass('force-bg-white');
    $('#modal #league-contributions_length select').addClass('force-bg-white');
    $('#modal table.table tr th, #modal table.table tr td').addClass('text-inverse');
    $('#modal .contribution-input[disabled=false], #modal .form-check-input[disabled=false]')
      .removeAttr('disabled');
    $('#modal .form-check-input[checked=false]').removeAttr('checked');
  });

  $(document).on('change', '.contribution-input', function(){
    var leagueId = $(this).attr('data-league-id');
    var payment = findContribution(leagueId);

    if (!payment) {
      gon.contributions.push({
        amount: $(this).val(),
        pay_for_all: false,
        league_id: leagueId
      });
    } else {
      var index = gon.contributions.indexOf(payment);
      payment.amount = $(this).val();
      payment.league_id = leagueId;
      gon.contributions[index] = payment;
    }
  });

  $(document).on('change', '#modal .form-check-input', function(){
    var leagueId = $(this).attr('data-league-id');
    var payment = findContribution(leagueId);

    if (!payment) {
      gon.contributions.push({
        amount: 0,
        pay_for_all: $(this).prop('checked'),
        league_id: leagueId
      });
    } else {
      var index = gon.contributions.indexOf(payment);
      payment.pay_for_all = $(this).prop('checked');
      payment.league_id = leagueId;
      gon.contributions[index] = payment;
    }

    updateContributionInput(leagueId);
  });

  $('#save-contributions').on('click', function() {
    $(this).attr('disabled', 'disabled');
    var url = postPath;
    $.ajax({
      type: 'PUT',
      url: url,
      data: { contributions: gon.contributions },
      success: function(){
        $('#modal').modal('hide');
        window.leaguesDashboard.draw()
      }
    });
  });
}
