$.extend($.fn.pickadate.defaults, { format: 'mm/dd/yyyy' });
$.extend($.fn.pickatime.defaults, { format: 'h:i A', interval: 15 });
window.TimePickerDefaultSettings = {
  format: 'hh:mm A',
  time: true,
  shortTime: true,
  date: false,
  switchOnClick: true,
};

var changeSubmitLabel = function () {
  if ($('[name="notification_message[schedule]"]:checked').val() === 'false')
    $('[type=submit]').val('SEND').attr('data-disable-with', 'SEND');
  else if (
    $('[name="notification_message[schedule]"]:checked').val() === 'true'
  )
    $('[type=submit]').val('SCHEDULE').attr('data-disable-with', 'SCHEDULE');
};

$(document).on('turbolinks:load', function () {
  new FroalaEditor('#notification_message_email_message', { tabSpaces: 4 });
  new FroalaEditor('#notification_message_push_message', { tabSpaces: 4 });

  var confirm = (field, callback) => {
    window.swalConfirm
      .fire({
        title: 'Are you sure?',
        text: `This action will replace the current content of ${field}`,
        confirmButtonText: `Yes`,
        cancelButtonText: 'No',
      })
      .then((result) => {
        if (result.isConfirmed) callback();
      });
  };

  $('.message-copy-text').on('click', function (e) {
    e.preventDefault();

    var that = this;
    var target = $(that).data('target').includes('fr') ? 'email' : 'SMS';
    confirm(target, () => {
      let value;
      if ($(that).data('target').includes('fr')) {
        $('.fr-placeholder').remove();
        value = $('#notification_message_sms_message')
          .val()
          .replaceAll('\n', '</p><p>');
        value = `<p>${value}</p>`;
      } else {
        const html = $('.fr-element.fr-view')
          .html()
          .replaceAll('</p>', '\n</p>');
        var div = document.createElement('div');
        div.innerHTML = html;
        value = div.textContent || div.innerText || '';
      }
      $($(that).data('target')).val(value);
      $($(that).data('target')).html(value).trigger('change');
    });
  });

  const showOrHideAttachment = () => {
    if ($('#notification_message_email').prop('checked')) {
      $('.attachment-box').removeClass('d-none');
      return;
    }

    $('.attachment-box').addClass('d-none');
  };

  $('#notification_message_sms')
    .on('change', function () {
      const target = $('.sms-message');
      $(this).prop('checked') ? target.show() : target.hide();
      showOrHideAttachment();
    })
    .trigger('change');

  $('#notification_message_push')
    .on('change', function () {
      const target = $('.push-message');
      $(this).prop('checked') ? target.show() : target.hide();
      showOrHideAttachment();
    })
    .trigger('change');

  $('#notification_message_email')
    .on('change', function () {
      const target = $('.email-message');
      $(this).prop('checked') ? target.show() : target.hide();
      showOrHideAttachment();
    })
    .trigger('change');

  $('#notification-messages-check-all').on('click', function (e) {
    e.preventDefault();
    $("[name*='notification_message[people']").prop('checked', true);
  });

  $('#notification-messages-uncheck-all').on('click', function (e) {
    e.preventDefault();
    $("[name*='notification_message[people']").prop('checked', false);
  });

  $('#notification-messages-uncheck-subs').on('click', function (e) {
    e.preventDefault();
    $(".sub[name*='notification_message[people']").prop('checked', false);
  });

  $('#notification-messages-uncheck-players').on('click', function (e) {
    e.preventDefault();
    $("[name*='notification_message[people']:not(.sub)").prop('checked', false);
  });

  var showInactiveLeagues = false;
  $('.controller-notification_messages #show-inactives').on('click', (e) => {
    e.preventDefault();
    showInactiveLeagues = !showInactiveLeagues;
    var text = showInactiveLeagues
      ? 'Hide inactive leagues'
      : 'Also show inactive leagues';
    $(e.target).text(text);
    var klass = '.controller-notification_messages .inactives';
    if (showInactiveLeagues) {
      $(klass).removeClass('d-none');
    } else {
      $(klass).addClass('d-none');
      $(klass).find('input').prop('checked', false);
    }
  });

  $('#notification_message_schedule_date').mask('00/00/0000').pickadate();
  $('#notification_message_schedule_time').bootstrapMaterialDatePicker(
    window.TimePickerDefaultSettings
  );

  $('#notification_message_people').select2();

  $('[name="notification_message[schedule]"]').on('change', function () {
    if ($(this).val() === 'false') {
      $('.schedule').addClass('d-none').find('input').val('');
    } else {
      $('.schedule').removeClass('d-none');
    }
    changeSubmitLabel();
  });

  $('[name="notification_message[who_option]"]').on('change', function () {
    if ($(this).val() === 'everyone') {
      $('.people-box').addClass('d-none');
    } else {
      $('.people-box').removeClass('d-none');
    }
  });

  $('.send-test-email').on('click', function (e) {
    window.stopEvent(e);

    $.ajax({
      url: '/notification_messages/send_test',
      data: {
        notification_message: {
          text: $('#notification_message_email_message').val(),
          subject: $('#notification_message_subject').val(),
        },
      },
      method: 'POST',
      success: () =>
        window.swalWithBootstrapButtons.fire({
          title: 'Your test was successfully sent.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK!',
        }),
    });
  });

  $('.resend-notification').on('click', function (e) {
    window.stopEvent(e);
    const that = e.target;

    window.swalConfirm
      .fire({
        title: $(this).attr('data-swal-title') || 'Are you sure?',
        text: $(this).attr('data-swal-text'),
        cancelButtonText: $(this).attr('data-swal-cancel-button-text') || 'No',
        confirmButtonText:
          $(this).attr('data-swal-confirm-button-text') || 'Yes',
      })
      .then((result) => {
        if (!result.isConfirmed) return;

        $(that).remove();
        const authenticity_token = $('meta[name=csrf-token]').attr('content');
        $.ajax({
          method: $(that).attr('data-method'),
          url: $(that).attr('href'),
          data: { authenticity_token },
        });
      });
  });

  changeSubmitLabel();
});
