(function () {
  const linkTarget = '.calculation-blocked-link';
  const text = 'If you make changes to this round, it will require you to recalculate this and all future rounds which may affect standings.';

  const calculationLocksApp = function () {
    const updateCalculationLocks = (roundId) => {
      var data = { type: 'uncalculate', league_setting_round_type_id: roundId }
      var method = 'POST';
      var url = `/dashboards/leagues/${gon.league_id}/set_calculation`;
      $.ajax({ url, method, data });
    }

    const questionUser = function(e) {
      e.preventDefault();
      var roundId = $(this).data('round-id');
      window.swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I\'m sure!',
        reverseButtons: true,
        cancelButtonText: 'No, cancel',
      }).then(function(result) {
        if (result.isConfirmed)
          updateCalculationLocks(roundId);
      });
    }

    $(document).on('click', linkTarget, questionUser);
  };

  if (typeof window !== "undefined") {
    window.calculationLocksApp = calculationLocksApp;
  }

  return calculationLocksApp();
})();
