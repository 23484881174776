$(document).on('change', '.change-player-show', function() {
  var show = !$(this).prop('checked');
  $('.scores-loader').removeClass('d-none');
  $.ajax({
    type: 'PUT',
    url: $(this).data('update-url'),
    data: { match_player_configuration: { show } },
  });
});

$(document).on('click', '.controller-scorings-scores .set-team-as-no-show', function(e) {
  e.preventDefault();

  var that = this;
  window.swalConfirm
        .fire({ confirmButtonText: 'Yes' })
        .then(result => {
    if (!result.isConfirmed) return;

    $('.scores-loader').removeClass('d-none');
    $.ajax({
      type: 'PUT',
      url: $(that).data('url'),
      success: function() {
        window.location.reload();
      }
    });
  });
});

$(document).on('click', '.controller-scorings-scores .set-teams-as-missing', function(e) {
  e.preventDefault();

  var that = this;
  window.swalConfirm
        .fire({ text: 'This will mark all remaining teams without scores as no shows.',
                confirmButtonText: 'Yes' })
        .then(result => {
    if (!result.isConfirmed) return;

    $('.scores-loader').removeClass('d-none');
    $.ajax({
      type: 'PUT',
      url: $(that).data('url'),
      success: function() {
        window.location.reload();
      }
    });
  });
});