(function () {
  const supportApp = function () {
    const sortSupportRows = () => {
      const sortable = new Sortable($('table tbody')[0], {
        animation: 150,
        ghostClass: 'bg-info',
        onEnd: () => {
          $('.generic-loader').removeClass('d-none');
          $.ajax({
            url: '/manage_supports/sort',
            method: 'PUT',
            data: { sort: sortable.toArray() },
            success: () => $('.generic-loader').addClass('d-none'),
          });
        },
      });
    };

    $(document).on('turbolinks:load', function () {
      // if (!$('body').attr('class').includes('controller-manage_supports')) return;
      // sortSupportRows();
    });
  };

  if (typeof window !== 'undefined') {
    window.supportApp = supportApp();
  }

  return supportApp;
})();
