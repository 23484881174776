const bindSweetAlert = function (e) {
  if ($(this).attr('data-swal-confirm') === 'false') return;

  window.stopEvent(e);

  const that = e.target;
  window.swalConfirm
    .fire({
      title: $(this).attr('data-swal-title') || 'Are you sure?',
      text: $(this).attr('data-swal-text'),
      showCancelButton: $(this).attr('data-swal-show-cancel-button') != 'false',
      cancelButtonText: $(this).attr('data-swal-cancel-button-text') || 'No',
      confirmButtonText: $(this).attr('data-swal-confirm-button-text') || 'Yes',
    })
    .then((result) => {
      if (!result.isConfirmed) return;

      $('body').off('click', '[data-swal-confirm]', bindSweetAlert);
      if ($(that).attr('data-swal-show-loader')) {
        $('.generic-loader').removeClass('d-none');
      }
      if ($(that).attr('data-method')) {
        const authenticity_token = $('meta[name=csrf-token]').attr('content');
        $.ajax({
          method: $(that).attr('data-method'),
          url: $(that).attr('href'),
          data: { authenticity_token },
        });
      } else {
        $(that).trigger('click');
      }
    });
};

$(document).on('turbolinks:load', function () {
  $('body').on('click', '[data-swal-confirm]', bindSweetAlert);
});
