$(document).on('turbolinks:load', function () {
  var LoadNotifications = function () {
    var bell = $('.js-notification-icon'),
      list = $('.js-notifications-list'),
      __this = this;

    this.bind = function () {
      if (window.location.href.includes(':3000')) return false;

      bell.on('click', function (e) {
        __this.setAsSeen();
      });

      setInterval(function () {
        __this.load();
      }, 300000);
    };

    this.setAsSeen = function () {
      $.get(bell.data('url'));
      __this.unnotify();
    };

    this.load = function () {
      $.get(list.data('url'), function (response) {
        __this.populate(response.data);
      });
    };

    this.populate = function (data) {
      list.html('');

      if (data.length > 0) {
        __this.unnotify();
        data.forEach((notification) => {
          list.append(__this.item(notification));
          if (!notification.seen) {
            __this.notify();
          }
        });
      } else {
        list.append(__this.empty());
        __this.unnotify();
      }
    };

    this.item = function (notification) {
      return Mustache.render($('#notification_item').html(), notification);
    };

    this.empty = function () {
      var object = { title: 'No notifications yet!', message: '', date: '' };
      return Mustache.render($('#notification_item').html(), object);
    };

    this.notify = function () {
      $('.notify').removeClass('d-none');
    };

    this.unnotify = function () {
      $('.notify').addClass('d-none');
    };
  };

  var loadNotifications = new LoadNotifications();
  loadNotifications.bind();
});
