const updateNetGrossCount = () => {
  ['net', 'gross'].map(netGross => {
    const length = $(`tbody [type=checkbox][name*=${netGross}_skin]:checked`).length;
    $(`.${netGross}-count`).text(`(${length})`);
  });

};

$(document).on('turbolinks:load', function(){
  if (!$('body').hasClass('controller-leagues-skins')) return;

  $('.controller-leagues-skins .select2-bg-transparent').select2().on('change', function() {
    window.location.href = `/leagues/${$(this).attr('league_id')}/skins?&round_type=${$('.round-type-skins').val()}&division=${$('.division-skins').val()}`;
  });

  [3, 4, 5].map(par => {
    $('input[id*="skin_pars_' + par + '_choose_"]').on('click', function(){
      if (this.value === 'true') {
        $('#skin_pars_' + par).parent().removeClass('d-none');
      } else {
        $('#skin_pars_' + par).parent().addClass('d-none');
      }
    });
  });

  ['net', 'gross'].map(netGross => {
    $('.js-check-all-' + netGross + '-skins').on('click', function() {
      $('.js-members-checkbox-' + netGross).prop('checked', $(this).is(':checked'));
      updateNetGrossCount();
    });
    $('.js-members-checkbox-'+ netGross).on('change', e => {
      $('.js-check-all-'+ netGross + '-skins').prop('checked',
      $('.js-members-checkbox-'+ netGross).length == $('.js-members-checkbox-'+ netGross +':checked').length);
      updateNetGrossCount();
    });
  });

  updateNetGrossCount();

  $('tbody [type=checkbox]').on('change', updateNetGrossCount);

  $('.submit-skins').on('click', function(e) {
    if ($(this).data('calculated')) {
      window.stopEvent(e);
      const form = this.form;

      const text = 'If you make changes to skins you must recalculate your round to update the skins results.';
      window.swalConfirm.fire({
        title: 'Warning',
        text,
        confirmButtonText: 'Save skins setup',
        cancelButtonText: 'Cancel',
      })
      .then((result) => {
        if (!result.isConfirmed) return false;

        $('.generic-loader').removeClass('d-none');
        $(form).trigger('submit');
      });
    }
  });
});
