$(document).on('turbolinks:load', function(){
  window.paidPlayersDatatable = $('#paid-players-dashboard').DataTable({
    info: false,
    ajax: {
      url: '/leagues/' + gon.league_id + '/players?filter_by=paid',
    },
    columns: [
      {
        data: function(data){
          var mustacheData = { initialLetter: data.full_name[0].toUpperCase(), fullName: data.full_name }
          return Mustache.render($('#mustache_player_full_name_column').html(), mustacheData);
        }
      },
      {
        data: function(data){
          return Mustache.render($('#mustache_verified_icon').html(), data);
        },
        className: 'text-center vertical-align-middle'
      },
      {
        data: function(data){
          return Mustache.render($('#mustache_player_value').html(), { text: data.paid_value });
        },
        className: 'text-right'
      },
    ],
  }).on('draw.dt', window.bindDatatableTooltips);

  window.unpaidPlayersDatatable = $('#unpaid-players-dashboard').DataTable({
    info: false,
    ajax: {
      url: '/leagues/' + gon.league_id + '/players?filter_by=unpaid',
    },
    columns: [
      {
        data: function(data){
          var mustacheData = { initialLetter: data.full_name[0].toUpperCase(), fullName: data.full_name }
          return Mustache.render($('#mustache_player_full_name_column').html(), mustacheData);
        }
      },
      {
        data: function(data){
          return Mustache.render($('#mustache_verified_icon').html(), data);
        },
        className: 'text-center vertical-align-middle'
      },
      {
        data: function(data){
          return Mustache.render($('#mustache_player_pay_now').html(), { playerId: data.id });
        }
      },
      {
        data: function(data){
          return Mustache.render($('#mustache_player_value').html(), { text: data.value_to_pay });
        },
        className: 'text-right'
      },
    ],
  }).on('draw.dt', window.bindDatatableTooltips);
});