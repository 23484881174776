$(document).on("turbolinks:load", function () {
  $(document).on("click", "#show-course-stats", function () {
    $(".generic-loader").removeClass("d-none");
    $("#show-course-stats").addClass("d-none");
  });
  $("#courses-dashboard").DataTable({
    ajax: {
      url: `/courses?order_by=leagues_count&year=${gon.year}&admin_dashboard=true${
        gon.white_label_app ? `&white_label_app=${gon.white_label_app}` : ""
      }`,
    },
    columns: [
      {
        data: "name",
      },
      {
        data: function (data) {
          return data.city + "/" + data.state_name;
        },
      },
      {
        data: "leagues_count",
        className: "text-right",
      },
      {
        data: "players_count",
        className: "text-right",
      },
    ],
  });
});
