$(document).on("turbolinks:load", function () {
  if (!$("body").hasClass("controller-leagues-cards_signs")) return;

  $(".round-type-cards-signs").select2().trigger("change");
  $(".select-cards-per-page").select2({ minimumResultsForSearch: -1 });
  $(".select-cards-dots").select2({ minimumResultsForSearch: -1 });
  $(".select-line-options").select2({ minimumResultsForSearch: -1 });
  $(".select-format-type").select2({ minimumResultsForSearch: -1 });

  $(".select-line-options")
    .on("change", function () {
      if ($(this).val() == "no_extra") $(".extra-line-text-group").addClass("d-none");
      else $(".extra-line-text-group").removeClass("d-none");
    })
    .trigger("change");

  setTimeout(() => {
    $("select.custom-select, select.select2").trigger("change");
  }, 200);

  $("a#remove-logo").on("click", function (e) {
    window.stopEvent(e);

    $(this).parent().find("input").val("true");
    $("#card-image").attr("src", "");
    $(this).addClass("d-none");
    $("input[type=file].custom-file").val("");
  });

  $("input[type=file].custom-file").on("change", function (e) {
    if (e.target.files.length > 0) {
      $("a#remove-logo").parent().find("input").val("");
    }
  });
});
