var playAgainstParHandicap = function (data) {
  handicap_description = "+" + data.league.additional_strokes_to_par;
  return handicap_description === "+0" ? "0" : handicap_description;
};

var getHandicapDescription = function (player, data) {
  var handicap_description;
  if (player.handicap === "NA") {
    return player.handicap;
  }

  if (typeof player.handicap === "number") {
    handicap_description = player.handicap < 0 ? "+" : "";
    var handicap = player.handicap === 0 ? player.handicap : Math.abs(player.handicap);
    handicap_description += handicap.toString();
    if (player.play_against_par) handicap_description = playAgainstParHandicap(data);
  }
  return handicap_description;
};

var getFirstLetters = function (player) {
  var first_name = player.first_name;
  var last_name = player.last_name;

  first_letters = "";
  if (first_name && last_name) first_letters = first_name.trim()[0] + last_name.trim()[0];

  return first_letters;
};

var getHideOverrideLink = function (data, player, team) {
  var team_play_against_par =
    team.players.find((player) => player.play_against_par === true) !== undefined;
  var team_is_blind_draw = team.players.find((player) => player.is_blind_draw) !== undefined;
  var hide_override_link = player.is_blind_draw || player.play_against_par || team.odd_blind_draw;
  if (data.match_format === "scramble")
    hide_override_link = hide_override_link || team_is_blind_draw || team_play_against_par;
  return hide_override_link;
};

var addInfosToPlayers = (player, data, team) => {
  var league_play_against_par = data.league.missing_player_handle_mode === "play_against_par";
  var actions_class = data.match_format === "scramble" || team.odd_blind_draw;
  actions_class = actions_class ? "visibility-hidden" : "";
  return {
    hide_override_link: getHideOverrideLink(data, player, team),
    league_play_against_par,
    first_letters: getFirstLetters(player),
    handicap_description: getHandicapDescription(player, data),
    actions_class,
    matchId: data.id,
    teamId: team.id,
    showClass: player.show ? "" : "missing",
    ...player,
  };
};

var getTeamHandicapDescription = function (data, team) {
  var handicap_description = "";
  if (team.league_play_against_par && team.play_against_par) {
    handicap_description = playAgainstParHandicap(data);
  } else {
    handicap_description = getHandicapDescription(team, data);
  }
  return handicap_description;
};

var getTeams = function (data) {
  var teams = data.teams
    .filter((team) => team.play_against_par !== true)
    .map((team) => {
      var team_name = team.name;
      team_name += team.odd_blind_draw || team.is_team_blind_draw ? " (Blind draw)" : "";
      return {
        ...team,
        players: team.players.map((player) => addInfosToPlayers(player, data, team)),
        override_handicap: team.players[0]?.override_handicap,
        handicap_description: getTeamHandicapDescription(data, team),
        is_blind_draw: team.players.find((player) => player.is_blind_draw) !== undefined,
        league_play_against_par: data.league.missing_player_handle_mode === "play_against_par",
        play_against_par:
          team.players.find((player) => player.play_against_par === true) !== undefined,
        matchId: data.id,
        actions_class: team.odd_blind_draw ? "visibility-hidden" : "",
        name: team_name,
        teamId: team.id,
        show: team.players[0]?.show,
        id: team.players[0]?.id,
      };
    });

  if (data.league.league_format_type == "with_pairings" && teams.length === 1) {
    handicap_description = playAgainstParHandicap(data);
    teams.push({
      name: data.league.missing_player_handle_mode?.includes("blind_draw")
        ? "Blind Draw"
        : "Play against par",
      handicap_description,
      hide_team: true,
      players: [],
    });
  }

  return teams;
};

window.getMatch = function (data) {
  return {
    id: data.id,
    date: data.date,
    show_division: data.league.total_divisions > 1,
    show_hole: data.league.tee_off_type == "shotgun",
    hole: data.initial_hole,
    division: data.division,
    course_name: data.course_name,
    show_player_handicap: data.match_format !== "scramble",
    play_against_par: data.league.missing_player_handle_mode === "play_against_par",
    player_id: data.scramble_player_id,
    calculated: data.calculated,
    teams: getTeams(data),
    page: window.pairingsTable.page(),
  };
};
