(function () {
  const checkboxSelector = ".pay-check";
  const totalSelector = ".total";
  const paymentLoader = ".payment-loader";
  const payBtn = ".pay-btn";
  const stripeModal = ".stripe-modal";
  const modalPayButton = ".stripe-modal .pay-button";
  const leaguesInput = "#payment_leagues";
  const payForAllLink = ".pay-for-all-leagues";

  const coursePayments = function () {
    let total = 0;

    const updateTotal = () => {
      total = 0;
      $(`${checkboxSelector}:checked`).each((_, elem) => {
        total += parseFloat($(elem).parent().parent().data("due-value"));
      });
      $(totalSelector).text(total.toFixed(2));
    };

    const openPayModal = () => {
      const leagueIds = [];
      $(`${checkboxSelector}:checked`).each((_, elem) => {
        leagueIds.push($(elem).parent().parent().data("league-id"));
      });
      $(leaguesInput).val(JSON.stringify(leagueIds));
      $(modalPayButton).text(`PAY ${total.toFixed(2)}`);
      $(stripeModal).modal("show");
    };

    const pay = () => {
      if (total <= 0) return;

      if (gon.payment_methods.length === 0)
        return $.ajax({
          url: `/payments/courses/${gon.course_id}/payment_methods/new`,
          success: () => {
            $(paymentLoader).addClass("d-none");
          },
        });
      openPayModal();
    };

    const payForAll = (e) => {
      e.preventDefault();
      $(checkboxSelector).prop("checked", true);
      updateTotal();
    };

    const init = () => {
      $(checkboxSelector).on("change", updateTotal);
      $(payBtn).on("click", pay);
      $(payForAllLink).on("click", payForAll);
      updateTotal();
    };

    $(document).on("turbolinks:load", () => {
      if ($("body").hasClass("controller-payments-courses")) init();
    });

    return { openPayModal };
  };

  if (typeof window !== "undefined") {
    window.coursePayments = coursePayments();
  }
})();
