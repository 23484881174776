require('./actions.js');
require('./skins.js');
require('./subs.js');
require('./standings.js');
require('./extra_points.js');
require('./cards_signs.js');
require('./notification_messages.js');
require('./mobile_scoring.js');
require('./block_roster.js');
require('./division_overrides.js');
require('./player_categories_tees.js');
require('./kiosk.js');
require('./round_types/init.js');
require('./matches/replaces.js');
require('./matches/sort_players.js');
require('./matches/match_overrides.js');
require('./matches/player_overrides.js');
require('./matches/scores.js');
require('./matches/configurations.js');
require('../league_settings/players/upload_verification');
require('./invite_players/list.js');
require('./invite_players/form.js');
require('./submit_scores.js');
require('./custom_tees.js');
require('./pollings.js');
require('./share_reports.js');
