// Sort topic by drag and drop
const sortTopics = () => {
  const onDrop = function (el, target) {
    $('.loader').removeClass('d-none');

    const topics = $('[data-topic-id]').toArray();
    const params = { league_id: gon.league_id, topics: [] };

    for (let i = 0; i < topics.length; i++)
      params['topics'].push({ id: $(topics[i]).data('topic-id'), sort: i });

    $.ajax({
      method: 'POST',
      url: `/forum/${gon.topicable_id}/topics/sort`,
      data: params,
      success: function () {
        $('.loader').addClass('d-none');
      },
    });
  };

  dragula([$('.topics')[0]], {
    moves: function (_el, _container, handle) {
      return handle.classList.contains('mdi-cursor-move');
    },
  }).on('drop', onDrop);
};

// Open and hide topic contents
const bindToggleTopic = () => {
  $('.toggle').on('click', function (e) {
    e.preventDefault();
    $(this).parent().parent().parent().find('.content').toggle();
    $(this)
      .find('i')
      .toggleClass('mdi-chevron-down')
      .toggleClass('mdi-chevron-up');
  });
};

// select a forum
const bindSelectForum = () => {
  $('.forum-select').on('change', function (e) {
    window.location.href = `forum/${$(this).val()}/topics`;
  });
};

$(document).on('turbolinks:load', function () {
  if (!$('body').attr('class').includes('controller-forum-')) return;

  bindToggleTopic();

  bindSelectForum();

  sortTopics();

  new FroalaEditor('#forum_reply_text', {
    height: 300,
    pluginsEnabled: ['link', 'colors', 'lists'],
  });
});
