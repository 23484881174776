var toDateString = function(date){
  var date = new Date(date);
  var month = String("00" + (date.getMonth() + 1)).slice(-2);
  var day = String("00" + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join('-')
}

$(document).on('turbolinks:load', function(){
  var calendarEl = document.getElementById('calendar');

  if (calendarEl) {
    var calendar = new window.Calendar(calendarEl,{
      plugins: [ window.CalendarDayGridPlugin ],
      handleWindowResize: true,
      events: gon.leagues,
      eventDataTransform: function(league) {
        return {
          id: league.id,
          title: league.name + ' - ' + league.schedule_date_settings.start_time,
          start: toDateString(league.schedule_date_settings.start_date)
        };
      }
    });

    setTimeout(function(){
      calendar.render();
    }, 500)
  }
});