$(document).on('turbolinks:load', function(){
  $('#teams-extra-points').DataTable({
    info: false,
    pageLength: 5000,
    bPaginate: false,
    ajax: {
      url: '/leagues/' + gon.league_id + '/teams?all_divisions=true&round_type_id=' + gon.round_type_id,
    },
    columns: [
      {
        data: 'division_name',
      },
      {
        data: 'name',
      },
      {
        data: function(data) {
          var extraPoints = gon.team_extra_points;
          var elem = extraPoints.find(elem => elem.id == data.id);
          return Mustache.render(
            $('#mustache_input').html(),
            {
              competitor: data.id,
              extraPoints: elem?.points || data.extra_points,
              competitorType: 'Team',
              roundTypeId: gon.round_type_id
            }
          );
        },
        className: 'text-center w-10'
      },
    ]
  });
});