$(document).on("turbolinks:load", function () {
  var reportConfigs = [
    { element: "leagues", labels: ["Leagues"], data: gon.leagues_report },
    { element: "players", labels: ["Players"], data: gon.players_report },
  ];

  $.each(reportConfigs, (index, config) => {
    if ($("#" + config.element).length > 0)
      Morris.Bar({
        element: config.element,
        data: config.data,
        xkey: "y",
        ykeys: ["x"],
        barColors: [window.primaryColor],
        gridLineColor: "#eef0f2",
        resize: true,
        hideHover: true,
        labels: config.labels,
        xLabelMargin: 10,
      });
  });

  setTimeout(() => {
    $("#leagues, #players").trigger("resize");
  }, 200);
});
