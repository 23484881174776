(function () {
  const favoriteReportsApp = function () {
    const init = () => {
      $(document).on('click', '.set-favorite-report', function(e) {
        window.stopEvent(e);

        var createFav = $(this).text().includes('Add');
        var reportId = $(this).data('report');

        if (createFav) {
          $.ajax({
            url: `/leagues/${gon.league_id}/favorite_reports`,
            method: 'POST',
            data: { id: reportId }
          });
        } else {
          $.ajax({
            url: `/leagues/${gon.league_id}/favorite_reports/${reportId}`,
            method: 'DELETE',
          });
        }
      });
    }

    $(document).on('turbolinks:load', () => {
      if (!$('body').attr('class').includes('reports')) return;

      init();
    })
  };

  if (typeof window !== "undefined") {
    window.favoriteReportsApp = favoriteReportsApp();
  }

  return favoriteReportsApp;
})();
