$(document).on("click", "#save-override-form", function (e) {
  const saveOverride = () => {
    $("#persist").val(true);
    $(".overrides-loader").removeClass("d-none");
    $(".modal form [type=submit].d-none").trigger("click");
  };
  e.preventDefault();
  if (!window.unsavedChanges) return saveOverride();

  window.swalConfirm
    .fire({
      title: "Warning",
      text: "You have unsaved scoring changes. If you submit the override you will lose the scoring changes.",
      confirmButtonText: "Save override and discard scoring changes",
      cancelButtonText: "Cancel",
    })
    .then((result) => {
      if (!result.isConfirmed) return;

      saveOverride();
    });
});

$(document).on(
  "change",
  "#overrides-modal form select, #overrides-modal form input",
  function (e) {
    $(".overrides-loader").removeClass("d-none");
    $(".modal form [type=submit].d-none").trigger("click");
  }
);

$(document).on("change", "#player_override_handicap", function () {
  var handicap = "(" + $(this).val() + ")";
  $('[data-player-id="<%= @player.id %>"]')
    .find(".player-handicap")
    .html(handicap);
});
