(function () {
  const element = 'score-distribution-chart';

  const reportsPlayerDashboardScoreDistribution = function () {
    const data = gon.distribution.report;
    Highcharts.chart(element, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        marginRight: screen.width > 768 ? 80 : 0,
        backgroundColor: 'transparent',
        type: 'pie',
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: { point: { valueSuffix: '%' } },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      legend: {
        itemStyle: {
          color: Highcharts.getOptions().colors[1],
          width: 95
        }
      },
      series: [{
        name: 'Percentage',
        colorByPoint: true,
        data
      }]
    });
  };

  if (typeof window !== "undefined") {
    window.reportsPlayerDashboardScoreDistribution = reportsPlayerDashboardScoreDistribution;
  }

  return reportsPlayerDashboardScoreDistribution;
})();
