$(document).on("turbolinks:load", function () {
  var showInactiveLeagues = false;

  var sumMoney = function (api, columnName) {
    var values = getApiValues(api, columnName);
    var total = values.map((elem) => parseFloat(elem?.replace("$", ""))).reduce((a, b) => a + b);
    if (!total) return;
    return "$" + total.toFixed(2);
  };

  var sumPlayersCount = function (api, columnName) {
    return getApiValues(api, columnName).reduce((a, b) => a + b);
  };

  var getApiValues = function (api, columnName) {
    var count = api.data().rows().count();
    return api
      .data()
      .map((elem) => elem[columnName])
      .slice(0, count);
  };

  var getLeaguesUrl = () => {
    var url = `/leagues?course_id=${gon.course_id}`;
    if (showInactiveLeagues) return url;

    return url + "&hide_inactives=true";
  };

  $(".controller-dashboards-base #show-inactives").on("click", (e) => {
    e.preventDefault();
    showInactiveLeagues = !showInactiveLeagues;
    var text = showInactiveLeagues ? "Hide inactive leagues" : "Also show inactive leagues";
    $(e.target).text(text);
    window.leaguesDashboard.ajax.url(getLeaguesUrl());
    window.leaguesDashboard.draw();
  });

  window.leaguesDashboard = $("#leagues-dashboard")
    .DataTable({
      pageLength: 1000,
      bLengthChange: false,
      bPaginate: false,
      bInfo: false,
      ajax: {
        url: getLeaguesUrl(),
      },
      columns: [
        {
          data: function (data) {
            var url = `/dashboards/courses/${gon.course_id}/leagues/${data.id}`;
            var content = $("<a>")
              .attr("href", url)
              .attr("data-turbolinks", "false")
              .text(data.name)[0].outerHTML;
            if (!data.active) content += " (inactive)";
            if (data.traveling) content += " (traveling)";
            return content;
          },
        },
        {
          data: "last_invite_sent_at",
          className: "text-right",
        },
        {
          data: "admin_names",
          className: "text-right",
        },
        {
          data: "players_count",
          className: "text-right",
        },
        {
          data: "subs_count",
          className: "text-right",
        },
        {
          data: "course_due_value",
          className: "text-right text-danger",
        },
        {
          data: "course_paid_value",
          className: "text-right",
        },
        {
          data: "contribution",
          className: "text-right",
        },
        {
          data: function (data) {
            return Mustache.render($("#mustache_league_actions").html(), {
              ...data,
              courseId: gon.course_id,
            });
          },
          className: "text-right w-150px",
        },
      ],
      footerCallback: function () {
        var api = this.api();
        var columns = $("#leagues-dashboard").find("tfoot tr th");
        $(columns[3]).html(sumPlayersCount(api, "players_count"));
        $(columns[4]).html(sumPlayersCount(api, "subs_count"));
        $(columns[5]).html(sumMoney(api, "course_due_value"));
        $(columns[6]).html(sumMoney(api, "course_paid_value"));
      },
    })
    .on("draw.dt", function () {
      $(".course-contributions, .league-admins, .renew-league, .league-invites").tooltip({
        boundary: "window",
        container: "body",
      });
    });
});
