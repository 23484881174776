(function () {
  const LeaguePollingsApp = function () {
    dragula($('#answers').toArray(), {
      moves: function (el, container, handle) {
        return handle.classList.contains('mdi-cursor-move');
      },
    }).on('drop', () => {
      $(`#answers .nested-fields`).each((index, el) => {
        $(el).find('[name*=sort]').val(index);
      });
    });
  };

  const LeaguePollingResultsBarApp = function (element, data) {
    Morris.Bar({
      element: $(element),
      data,
      xkey: 'y',
      ykeys: ['x'],
      // barColors: Highcharts.getOptions().colors,
      barColors: function (row) {
        return Highcharts.getOptions().colors[row.x];
      },
      gridLineColor: '#eef0f2',
      resize: true,
      hideHover: true,
      labels: data.map((item) => 'Count'),
      xLabelMargin: 10,
    });
    setTimeout(() => {
      $('.modal #bar-chart').trigger('resize');
    }, 100);
  };

  const LeaguePollingResultsPieApp = function (element, data, intoACard) {
    Highcharts.chart(element, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        marginRight: screen.width > 768 ? 80 : 0,
        backgroundColor: 'transparent',
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: { point: { valueSuffix: '%' } },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      legend: {
        width: '200px',
        itemStyle: {
          color: Highcharts.getOptions().colors[intoACard ? 1 : 13],
          width: 95,
        },
      },
      series: [
        {
          name: 'Percentage',
          colorByPoint: true,
          data,
        },
      ],
    });
  };

  if (typeof window !== 'undefined') {
    window.LeaguePollingsApp = LeaguePollingsApp;
    window.LeaguePollingResultsBarApp = LeaguePollingResultsBarApp;
    window.LeaguePollingResultsPieApp = LeaguePollingResultsPieApp;
  }
})();
