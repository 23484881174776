(function () {
  const divisionOverridesApp = function () {
    const preventFormSubmit = (e) => {
      window.stopEvent(e);

      var target = $('#leagues_division_overrides_end_round_id')[0].form;

      window.swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: 'It will change your division teams.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, continue!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        .then((result) => {
          if (!result.isConfirmed) return;

          $('.loader.generic-loader').removeClass('d-none');
          $(target).off('submit', preventFormSubmit);
          $(target).trigger('submit');
        });
    };

    const init = function () {
      if (!$('body').hasClass('controller-leagues-division_overrides')) return;

      const form = $('#leagues_division_overrides_end_round_id')[0].form;
      $(form).on('submit', preventFormSubmit);
    };

    $(document).on('turbolinks:load', init);
  };

  if (typeof window !== 'undefined') {
    window.divisionOverridesApp = divisionOverridesApp();
  }

  return divisionOverridesApp;
})();
