$(document).on('turbolinks:load', function () {
  const changeOffLabel = (elem, value) => {
    var label = elem.parent().find('.bootstrap-switch-label');
    var offText;
    if (elem.hasClass('scores-type-toggle'))
      offText = value ? 'SCORES' : '+/- PAR';
    else if (elem.hasClass('skin-money-toggle'))
      offText = value ? '#' : '$';
    else if (elem.hasClass('report-competitor-type'))
      offText = value ? 'PLAYERS' : 'TEAMS';
    else if (elem.hasClass('report-competitor-scramble'))
      offText = !value ? 'PLAYERS' : 'TEAMS';
    else offText = value ? 'NET' : 'GROSS';
    label.text(offText);
  };

  setTimeout(function () {
    const selector =
      '.controller-reports-round_skins_summary .switch-input, \
       .controller-reports-round_skins_summary .skin-money-toggle';
    $(selector).each(function () {
      const target = this;
      $(this).bootstrapSwitch('state', $(this).attr('checked'));
      $(this).on('switchChange.bootstrapSwitch', function (_event, state) {
        changeOffLabel($(target), state);
        $(target).parent().parent().parent().find('input[type=hidden]')
                 .val(state ? $(target).attr('data-on-value') : $(target).attr('data-off-value'));
        setTimeout(() => $(target.form).trigger('submit'), 500);
      });
      changeOffLabel($(target), $(this).prop('checked'));
    });
  }, 500);

  setTimeout(function () {
    const selector =
      '.controller-reports-round_scores .switch-input, \
                      .controller-reports-round_scores .report-competitor-type';
    $(selector).each(function () {
      const target = this;
      $(this).on('switchChange.bootstrapSwitch', function (_event, state) {
        changeOffLabel($(target), state);
      });
      changeOffLabel($(target), $(this).prop('checked'));
    });
  }, 500);

  $('.select-sort-fields-options').select2({ minimumResultsForSearch: -1 });
  $('.select-order-fields-options').select2({ minimumResultsForSearch: -1 });

  if (
    $('body').attr('class').includes('controller-reports') ||
    $('body')
      .attr('class')
      .includes('controller-league_settings-pairings-matches')
  ) {
    $('.card.filters select, .card.filters input').on('change', function (e) {
      if (
        $(this).hasClass('no-submit') ||
        ($('body').hasClass('controller-reports-skins') &&
          $(this).hasClass('switch-input'))
      )
        return;

      $(e.target.form).trigger('submit');
    });
  }

  if ($('body').attr('class').includes('controller-reports-skins')) {
    const showLoader = () => $('.generic-loader').removeClass('d-none');

    $('.sort_link').on('click', showLoader);
    $('form').on('submit', showLoader);

    $('input.switch-input').hide();
    setTimeout(function () {
      $('input.switch-input').each(function () {
        const target = this;
        $(this).trigger('change');
        $(this).bootstrapSwitch('state', $(this).attr('checked'));
        $(this).on('switchChange.bootstrapSwitch', function (_event, state) {
          if (state) {
            $('.skins-report-gross').show();
            $('.skins-report-net').hide();
          } else {
            $('.skins-report-gross').hide();
            $('.skins-report-net').show();
          }
          changeOffLabel($(target), state);
        });
        changeOffLabel($(target), false);
        $('.skins-report-gross').hide();
        $('.skins-report-net').show();
      });
      if (
        $('.skins-report-gross table').length > 1 &&
        $('.skins-report-net table').length === 0
      )
        $('.bootstrap-switch-handle-off.bootstrap-switch-primary').trigger(
          'click'
        );
    }, 300);
  }

  if (
    $('body').attr('class').includes('controller-reports-handicaps') ||
    $('body').attr('class').includes('controller-reports-lowest_scores')
  ) {
    $('input.switch-input').hide();
    setTimeout(function () {
      $('input.switch-input').each(function () {
        const target = this;
        $(this).bootstrapSwitch('state', $(this).attr('checked'));
        $(this).on('switchChange.bootstrapSwitch', function (event, state) {
          $('#report_type_input').val(state ? 'players' : 'teams');
          changeOffLabel($(target), state);
          setTimeout(function () {
            $('.report-filters').trigger('submit');
          }, 300);
        });
        changeOffLabel($(target), $(this).prop('checked'));
      });
    }, 300);
  }

  if (
    $('body').attr('class').includes('controller-reports-round_scores') ||
    $('body').attr('class').includes('controller-reports-stats-par_diff') ||
    $('body').attr('class').includes('controller-reports-ringer')
  ) {
    $('input.switch-input').hide();
    setTimeout(function () {
      $('input.switch-input:not(.report-competitor-type-input)').each(
        function () {
          const target = this;
          $(this).bootstrapSwitch('state', $(this).attr('checked'));
          $(this).on('switchChange.bootstrapSwitch', function (event, state) {
            $('#report_type_input')
              .val(state ? 'gross' : 'net')
              .trigger('change');
            setTimeout(function () {
              $('#tee_id').trigger('change');
            }, 300);
            changeOffLabel($(target), state);
          });
          changeOffLabel($(target), $('#report_type_input').val() === 'gross');
        }
      );

      if ($('body').attr('class').includes('controller-reports-ringer')) return;

      $('input.report-competitor-type').each(function () {
        $(this).bootstrapSwitch('state', $(this).attr('checked'));
        $(this).on('switchChange.bootstrapSwitch', function (event, state) {
          $('#report_competitor_type').val(state ? 'teams' : 'players');
          setTimeout(function () {
            $('#tee_id').trigger('change');
          }, 300);
        });
      });

      $('input.scores-type-toggle').each(function () {
        $(this).bootstrapSwitch('state', $(this).attr('checked'));
        $(this).on('switchChange.bootstrapSwitch', function (e, state) {
          $('#scores_type_input')
            .val(state ? 'scores' : 'par')
            .trigger('change');
          var label = $(e.target).parent().find('.bootstrap-switch-label');
          var offText = $(e.target).prop('checked') ? '+/- PAR' : 'SCORES';
          label.text(offText);
        });
        var label = $(this).parent().find('.bootstrap-switch-label');
        var offText = $(this).prop('checked') ? '+/- PAR' : 'SCORES';
        label.text(offText);
      });
    }, 300);
  }

  $('body.controller-reports-handicaps.action-show [name=competitor_id]').on(
    'change',
    function () {
      let url = `${window.location.href.split('handicaps/')[0]}/handicaps/`;
      url += $(this).val();
      url += `?${window.location.href.split('?')[1]}`;
      window.location.href = url;
    }
  );

  if ($('body[class*=controller-reports] #competitor_type_input').length > 0) {
    $('input.competitor_type_input').hide();
    setTimeout(function () {
      $('input.report-competitor-type').each(function () {
        const target = this;
        $(this).bootstrapSwitch('state', $(this).attr('checked'));
        $(this).on('switchChange.bootstrapSwitch', function (_, state) {
          $('#competitor_type_input').val(!state ? 'teams' : 'players');
          changeOffLabel($(target), !state);
          setTimeout(function () {
            $($(target)[0].form).trigger('submit');
          }, 300);
        });
        changeOffLabel($(target), !$(this).prop('checked'));
      });
    }, 300);
  }

  $('.controller-reports-stats-par_diff #report_type_input').on(
    'change',
    function () {
      const { path, s, parDiff, leagueId } = $(this).data();
      const reportType = $(this).val();
      window.location.href = `${path}?league_id=${leagueId}&par_diff=${parDiff}&q[s]=${s}&report_type=${reportType}`;
    }
  );

  $('.controller-reports-standings #season_id').on('change', function (e) {
    window.stopEvent(e);

    const selectedOption = $(this).find(`option[value='${$(this).val()}']`);
    $('#from_round_id')
      .val($(selectedOption).attr('data-first-round-id'))
      .trigger('change');
    $('#to_round_id')
      .val($(selectedOption).attr('data-last-round-id'))
      .trigger('change');
  });
});
