window.primaryColor = '#2BB573';
require('./common.js');
require('../pages/dashboards/init.js');
require('../pages/leagues/init.js');
require('../pages/web_app/init.js');
require('../pages/reports/init.js');
require('../pages/courses/init.js');
require('../pages/leagues/notifications/init.js');
require('../pages/users/merges.js');
require('../pages/payments/init.js');
require('../pages/forums.js');
require('../pages/support.js');

require('stylesheets/theme/leagues');
