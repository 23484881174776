(function () {
  const blockRosterForm = 'form.js-block-roster';
  const blockRosterBtn = '.js-block-roster-btn';
  const bodyClassTeams = '.controller-league_settings-players-teams';

  const blockRosterApp = function () {
    const preventFormSubmit = (e) => {
      if ($(bodyClassTeams).length > 0) return true;

      window.stopEvent(e);

      var link = $('<a>').attr('href', `/leagues/${gon.league_id}/replaces/new`)
                         .attr('data-turbolinks', 'false')
                         .text('Replace Player / Add team');
      window.swalWithBootstrapButtons.fire({
        title: 'Oops',
        html: `Team changes are disabled because there are calculated rounds. \
               If you wish to make changes please use the ${link[0].outerHTML} utility.`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
    };

    const init = function() {
      $(blockRosterForm).on('submit', preventFormSubmit)
      $(blockRosterBtn).on('click', preventFormSubmit)
    }

    $(document).on('turbolinks:load', init);
  };

  if (typeof window !== "undefined") {
    window.blockRosterApp = blockRosterApp();
  }

  return blockRosterApp;
})();
